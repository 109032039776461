import React from "react";
import { any } from "prop-types";
import { Component } from "react";
import AppSettings from "../app-settings";
import SnackbarHelper from "../views/helper/SnackbarHelper.jsx";

// import SnackbarHelper from "../views/helper/SnackbarHelper.jsx";

class APIService extends Component {
  static is401Tried = false;
  static SnackbarHelperMessage = null;
  // static SnackbarHelperType = "error";
  static GET(
    APIUrl //, params = null, includeAuthHeaders = true)
  ) {
    let store = this.getStore();
    var result = fetch(APIUrl, {
      method: "GET",
      mode: "cors",
      crossDomain: true,
      headers: {
        "content-type": "application/json",
        accept: "application/json",
        Token: store.token,
        StoreId: store.id,
      },
    });

    return result
      .then(function (response) {
        self.SnackbarHelperMessage = null;
        if (response.status == 401) {
          if (APIService.is401Tried === true) {
            localStorage.clear();
            // window.location.assign("/error/401");
            window.location.assign("/refine/store");
          } else {
            APIService.is401Tried = true;
            return APIService.GenerateRefreshToken().then((response) => {
              if (response == true) {
                APIService.is401Tried = false;
                // if (confirm("Are you Want To Confirm"))
                return APIService.GET(APIUrl);
              } else {
                localStorage.clear();
                // window.location.assign("/error/401");
                window.location.assign("/refine/store");
              }
            });

            // continue start_position;
          }
        } else {
          return result;
        }
      })
      .catch(() => {
        // self.SnackbarHelperMessage =
        //     "Backend Server not responding!! Try Again Later";
        // throw err;
      });
  }
  static PUT(
    APIUrl,
    params = null
    //, includeAuthHeaders = true
  ) {
    let store = this.getStore();
    params = params == null ? any : params;
    //includeAuthHeaders = includeAuthHeaders;
    var result = fetch(APIUrl, {
      method: "PUT",
      mode: "cors",
      headers: {
        //"x-rapidapi-host": "fairestdb.p.rapidapi.com",
        //"x-rapidapi-key": "apikey",
        "content-type": "application/json",
        accept: "application/json",
        Token: store.token,
        StoreId: store.id,
      },
      body: JSON.stringify(params),
    });

    return result
      .then(function (response) {
        self.SnackbarHelperMessage = null;
        if (response.status == 401) {
          if (APIService.is401Tried === true) {
            localStorage.clear();
            // window.location.assign("/error/401");
            window.location.assign("/refine/store");
          } else {
            return (
              APIService.GenerateRefreshToken()
                // .then((response) => response.json())
                .then((response) => {
                  if (response == true) {
                    APIService.is401Tried = false;
                    return APIService.PUT(APIUrl, params);
                  } else {
                    localStorage.clear();
                    // window.location.assign("/error/401");
                    window.location.assign("/refine/store");
                  }
                })
            );
          }
        } else return result;
      })
      .catch(() => {
        // self.SnackbarHelperMessage =
        //     "Backend Server not responding!! Try Again Later";
        // throw err;
      });
  }

  static POST(APIUrl, params = null, includeAuthHeaders = true) {
    // let token = this.getToken();

    params = params == null ? any : params;
    let store = this.getStore();
    //includeAuthHeaders = includeAuthHeaders;
    var header = null;
    if (includeAuthHeaders) {
      header = {
        "content-type": "application/json",
        accept: "application/json",
        Token: store.token,
        StoreId: store.id,
      };
    } else {
      header = {
        "content-type": "application/json",
        accept: "application/json",
      };
    }

    var result = fetch(APIUrl, {
      method: "POST",
      mode: "cors",
      headers: header,
      body: JSON.stringify(params),
    });
    return result
      .then(function (response) {
        self.SnackbarHelperMessage = null;

        if (response.status == 401) {
          if (APIService.is401Tried === true) {
            localStorage.clear();
            // window.location.assign("/error/401");
            window.location.assign("/help");
          } else {
            return (
              APIService.GenerateRefreshToken()
                // .then((response) => response.json())
                .then((response) => {
                  if (response == true) {
                    APIService.is401Tried = false;
                    return APIService.POST(APIUrl, params, includeAuthHeaders);
                  } else {
                    localStorage.clear();
                    // window.location.assign("/error/401");
                    window.location.assign("/help");
                  }
                })
            );
          }
        } else return result;
      })
      .catch(() => {
        // self.SnackbarHelperMessage =
        //     "Backend Server not responding!! Try Again Later";
        // throw err;
      });
  }
  static FormPOST(APIUrl, formDate = null, includeAuthHeaders = true) {
    // let token = this.getToken();

    //includeAuthHeaders = includeAuthHeaders;
    var header = null;
    let store = this.getStore();
    if (includeAuthHeaders) {
      header = {
        accept: "application/json",
        Token: store.token,
        StoreId: store.id,
      };
    } else {
      header = {
        accept: "application/json",
      };
    }

    var result = fetch(APIUrl, {
      method: "POST",
      mode: "cors",
      headers: header,
      body: formDate,
    });
    return result
      .then(function (response) {
        self.SnackbarHelperMessage = null;

        if (response.status == 401) {
          if (APIService.is401Tried === true) {
            localStorage.clear();
            // window.location.assign("/error/401");
            window.location.assign("/help");
          } else {
            return (
              APIService.GenerateRefreshToken()
                // .then((response) => response.json())
                .then((response) => {
                  if (response == true) {
                    APIService.is401Tried = false;
                    return APIService.FormPOST(
                      APIUrl,
                      formDate,
                      includeAuthHeaders
                    );
                  } else {
                    localStorage.clear();
                    // window.location.assign("/error/401");
                    window.location.assign("/help");
                  }
                })
            );
          }
        } else return result;
      })
      .catch(() => {
        // self.SnackbarHelperMessage =
        //     "Backend Server not responding!! Try Again Later";
        // throw err;
      });
  }
  static getToken() {
    var stores = localStorage.getItem("stores");
    if (stores && stores.length > 0) {
      stores = JSON.parse(stores);
      if (stores) {
        var selectedStore = stores.filter((x) => x.is_selected == true);

        if (selectedStore && selectedStore.length > 0) {
          selectedStore = selectedStore[0];
        } else {
          selectedStore = stores[0];
        }
        return selectedStore.token;
      }
    }
    return false;
  }
  static getStore() {
    var stores = localStorage.getItem("stores");
    if (stores && stores.length > 0) {
      stores = JSON.parse(stores);
      if (stores) {
        var selectedStore = stores.filter((x) => x.is_selected == true);

        if (selectedStore && selectedStore.length > 0) {
          selectedStore = selectedStore[0];
        } else {
          selectedStore = stores[0];
        }
        return selectedStore;
      }
    }
    return false;
  }
  static CheckStoreApiExist() {
    var stores = localStorage.getItem("stores");
    stores = JSON.parse(stores);
    if (stores && stores !== null && stores.length > 0) {
      var selectedStore = stores.filter((x) => x.is_selected == true);

      if (selectedStore && selectedStore.length > 0) {
        selectedStore = selectedStore[0];
      } else {
        selectedStore = stores[0];
      }
      if (selectedStore.is_configured == true) return true;
      else return false;
    } else {
      return false;
      // document.location = "/error/401";
    }
  }

  static UpdateStoreConfiguredStatus(value, storeConfig = null) {
    var stores = localStorage.getItem("stores");
    stores = JSON.parse(stores);
    var selectedStore = stores.filter((x) => x.is_selected == true);
    if (selectedStore && selectedStore.length > 0) {
      if (storeConfig) {
        selectedStore[0].storeConfig = storeConfig;
      }
      selectedStore[0].is_configured = value;
      stores = selectedStore;
      if (!stores) {
        // document.location = "/error/401";
        document.location = "/help";
      } else {
        localStorage.setItem("stores", JSON.stringify(stores));
      }
    }
  }
  static GetStoreConfig(key) {
    var storeConfig = localStorage.getItem("store_config");
    if (!storeConfig) {
      APIService.GET(AppSettings.GetSettingsAPI)
        .then((response) => response.json())
        .then((response) => {
          if (response && response.success && (response.success = "True")) {
            if (response.result.id != null) {
              // this.setState({ isExist: true });
              localStorage.setItem(
                "store_config",
                JSON.stringify(response.result)
              );
              document.getElementById("dispatches-list").style.display =
                response.result.batch_orders_auto == false &&
                response.result.batch_orders_manual == false
                  ? "none"
                  : "block";
              if (typeof response.result !== "undefined") {
                return response.result[key];
              } else {
                return false;
              }
            }
          }
        })
        .catch(() => {});
    } else {
      storeConfig = JSON.parse(storeConfig);
      if (typeof storeConfig !== "undefined") {
        return storeConfig[key];
      } else {
        return false;
      }
    }
  }

  static GetSelectedStoreName() {
    var stores = localStorage.getItem("stores");
    stores = JSON.parse(stores);
    var selectedStore = stores.filter((x) => x.is_selected == true);

    if (selectedStore && selectedStore.length > 0) {
      selectedStore = selectedStore[0];
    } else {
      selectedStore = stores[0];
    }
    return selectedStore.name;
  }
  static GenerateRefreshToken() {
    var stores = localStorage.getItem("stores");
    stores = JSON.parse(stores);
    var selectedStore = stores.filter((x) => x.is_selected == true);

    if (selectedStore && selectedStore.length > 0) {
      selectedStore = selectedStore[0];
    } else {
      selectedStore = stores[0];
    }
    const storeId = selectedStore.id;
    var data = {};
    data.store_id = storeId;
    return APIService.POST(AppSettings.GetRefreshToken, data, false)
      .then((response) => response.json())
      .then((response) => {
        if (response.success == true) {
          APIService.is401Tried = false;
          var token = response.data.token;
          stores[0].token = token;
          if (!stores) {
            // document.location = "/error/401";
            document.location = "/refine/store";
          } else {
            localStorage.setItem("stores", JSON.stringify(stores));
          }
          return true;
        } else {
          return false;
        }
      })
      .catch(() => {
        return false;
      });
    // return false;
  }
  render() {
    return (
      self.SnackbarHelperMessage != null && (
        <SnackbarHelper message={self.SnackbarHelperMessage} type={"error"} />
      )
    );
  }
}
export default APIService;

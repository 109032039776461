import React, { Component } from "react";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
  withStyles,
} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import { ReactComponent as NavDispatchesIcon } from "../../assets/img/navIcons_Dispatches.svg";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Alert from "@material-ui/lab/Alert";
import Skeleton from "@mui/material/Skeleton";
import AppSettings from "app-settings";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import OrderActionHelper from "views/helper/OrderActionHelper.jsx";
import { ReactComponent as BjsIcon } from "../../assets/img/BJSTypeLogo.svg";
import { ReactComponent as CancelOrdersIcon } from "../../assets/img/ctaIcons_Cancel.svg";
import { ReactComponent as DeleteOrdersIcon } from "../../assets/img/ctaIcons_Delete.svg";
import { ReactComponent as DispatchOrdersToBjsIcon } from "../../assets/img/ctaIcons_DispatchOrdersToBJS.svg";
import { ReactComponent as DispatchStockToBjsIcon } from "../../assets/img/ctaIcons_DispatchStockToBJS.svg";
import { ReactComponent as GenerateLabelsIcon } from "../../assets/img/ctaIcons_GenerateLabels.svg";
import { ReactComponent as SortByIcon } from "../../assets/img/icons_SortBy.svg";
import { ReactComponent as ShopifyIcon } from "../../assets/img/Shopify_LogoMark.svg";
import APIService from "../../services/APIService.js";
import Help from "../Help/Help.jsx";
import ApiResponseError from "../helper/ApiResponseError.jsx";
import APIResponsePopupHelper from "../helper/APIResponsePopupHelper.jsx";
import AutoReleasePopupHelper from "../helper/AutoReleasePopupHelper.jsx";
import CheckBoxHelper from "../helper/CheckBoxHelper.jsx";
import ConsignmentNoHelper from "../helper/ConsignmentNoHelper.jsx";
import DateConvertor from "../helper/core/DateConvertor.jsx";
import NoResultHelper from "../helper/NoResultHelper.jsx";
import OrderPopupTabIndexEnum from "../helper/OrderPopupTabIndexEnum.jsx";
import OrderStatus from "../helper/OrderStatus.jsx";
import OrderStatusEnum from "../helper/OrderStatusEnum.jsx";
import OrderTabEnum from "../helper/OrderTabEnum.jsx";
import Pagination from "../helper/PaginationHelper.jsx";
import SearchBarHelper from "../helper/SearchBarHelper.jsx";
import OrderDialogBox from "./OrderDialogBox.jsx";
import "./ordersPage.scss";

// icons_SortBy
// const { innerHeight: window_height } = window;
// const dataGridHeight = window_height - 230;
const styles = () => ({
  customTableContainer: {
    overflowX: "initial",
  },
});

class OrdersPage extends Component {
  constructor(props) {
    super(props);
    props.getHelp(Help.OrdersPage);
    this.state = {
      progress: "",
      multipleOrdersSelectedActions: {},
      errorAlert: null,
      total_record: 0,
      refreshBTN: true,
      showTotalRecord: 0, // Use in pagination && Show
      recordsCount: {
        pending: 0,
        completed: 0,
        cancelled: 0,
        active: 0,
        queued: 0,
        exception: 0,
      },
      pageSize: 10,
      loading: false,
      sortModel: [],
      actionButtonValue: null,
      popUpclickedOrder: null,
      falseActionPopup: null,
      rows: [],
      isDropdownAppear: [],
      filtration: {
        start: 0,
        limit: 30,
        isOrderProduct: "false",
        orderByKey: "Updated",
        order: "DESC",
        searchKey: "",
        isAllChecked: false,
        searchValueTypedOnly: "",
        searchValue: "",
        SortToDate: "",
        SortFromDate: "",
        status: OrderTabEnum.PENDING(),
        clickedOrderStatusId: OrderTabEnum.PENDING(),
        orderStatus: OrderTabEnum.PENDING(),
        sorting: "Updated-DESC",
        quickFilter: [],
        orderId: null,
        SelectedOrders: [],
        lastNMonthOrder: localStorage.getItem("lastNMonthOrder") ?? 1,
      },
      columns: [],
      Product: { productDetailsTab: 0 },
      batchOrdersRefrence: null,
      batchOrdersId: null,
    };
    this.handlePageChange = this.handlePageChange.bind(this);
    this.ApiRequest = this.ApiRequest.bind(this);
    this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
    this.handleSortModelChange = this.handleSortModelChange.bind(this);
    this.inputSearch = this.inputSearch.bind(this);
    this.SearchBox = this.SearchBox.bind(this);
    this.StartLoader = this.StartLoader.bind(this);
    this.productInfo = this.productInfo.bind(this);
    this.RefreshData = this.RefreshData.bind(this);
    this.CheckboxSelection = this.CheckboxSelection.bind(this);
    this.quickFilters = this.quickFilters.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
    this.CheckBoxOnSelect = this.CheckBoxOnSelect.bind(this);
    this.CheckBoxOnUnselect = this.CheckBoxOnUnselect.bind(this);
    this.CheckOrderStatusIsValidForGenerateLabels =
      this.CheckOrderStatusIsValidForGenerateLabels.bind(this);
    this.CheckOrderStatusIsValidForCreate =
      this.CheckOrderStatusIsValidForCreate.bind(this);
    this.CheckOrderStatusIsValidForRelease =
      this.CheckOrderStatusIsValidForRelease.bind(this);
    this.ShowError = this.ShowError.bind(this);
    this.CheckOrderStatusIsValidForCancelOrder =
      this.CheckOrderStatusIsValidForCancelOrder.bind(this);
    this.CheckOrderStatusIsValidForDeleteOrder =
      this.CheckOrderStatusIsValidForDeleteOrder.bind(this);
    this.CheckOrderStatusIsCheckBoxOperation =
      this.CheckOrderStatusIsCheckBoxOperation.bind(this);
  }

  CheckOrderStatusIsCheckBoxOperation = (row) => {
    let value = OrderStatusEnum.CheckOrderStatusIsCheckBoxOperation([row]);

    return (
      <>
        <CheckBoxHelper
          checked={
            this.state.filtration.SelectedOrders.includes(row) === true
              ? true
              : false
          }
          className={!value.success ? "disabled" : ""}
          onChange={(event, newVal) => {
            if (value.success) {
              if (newVal) {
                this.CheckBoxOnSelect(row);
              } else {
                this.CheckBoxOnUnselect(row);
              }
              if (!this.state.loading) {
                let filtration = this.state.filtration;

                if (this.state.rows.length > 0) {
                  filtration.isAllChecked =
                    this.state.rows.length == filtration.SelectedOrders.length;
                }
                this.setState({ filtration: filtration });
              }
              this.CheckboxSelection(this.state.filtration.SelectedOrders);
            }
          }}
        />
      </>
    );
  };
  // On CheckBoxOnSelect
  CheckOrderStatusIsValidForDeleteOrder = () => {
    let value = OrderStatusEnum.CheckOrderStatusIsValidForDeleteOrder(
      this.state.filtration.SelectedOrders
    );
    // let value = { success: true, message: null };multipleOrdersSelectedActions

    if (value.success == true) {
      // let multipleOrdersSelectedActions = Object.assign({}, this.state.multipleOrdersSelectedActions);
      // multipleOrdersSelectedActions = {
      //   icon: <DeleteOrdersIcon className="delete" />,
      //   title: "Delete",
      //   id: "order-action-button delete"
      // }
      // this.setState({ multipleOrdersSelectedActions: multipleOrdersSelectedActions });
      return (
        <>
          <Button
            className={"order-action-button delete"}
            id="Delete-Orders-Action"
            onClick={() => {
              // if (value.message) {
              //   this.ShowError(value.message);
              // } else
              this.setState({
                actionButtonValue: (
                  <OrderActionHelper
                    onSuccessClose={() => {
                      this.RefreshData();
                    }}
                    onCancelClose={() => {
                      this.setState({ actionButtonValue: null });
                    }}
                    action="Delete"
                    RefreshData={this.RefreshData}
                    actionTitle={
                      "Delete " +
                      (this.state.filtration.SelectedOrders.length == 1
                        ? "Order"
                        : "Orders") +
                      ""
                    }
                    actionIcon={<DeleteOrdersIcon className="delete" />}
                    Orders={this.state.filtration.SelectedOrders}
                  />
                ),
              });
            }}
          >
            <DeleteOrdersIcon /> Delete
          </Button>
        </>
      );
    }
  };

  // On CheckBoxOnSelect
  CheckOrderStatusIsValidForCancelOrder = () => {
    let value = OrderStatusEnum.CheckOrderStatusIsValidForCancelOrder(
      this.state.filtration.SelectedOrders
    );
    // let value = { success: true, message: null };

    if (value.success == true) {
      return (
        <>
          <Button
            id="Cancel-Orders-Action"
            className={"order-action-button cancel"}
            onClick={() => {
              // if (value.message) {
              //   this.ShowError(value.message);
              // } else
              this.setState({
                actionButtonValue: (
                  <OrderActionHelper
                    action="Cancel"
                    onSuccessClose={() => {
                      this.RefreshData();
                    }}
                    onCancelClose={() => {
                      this.setState({ actionButtonValue: null });
                    }}
                    actionTitle={
                      "Cancel " +
                      (this.state.filtration.SelectedOrders.length == 1
                        ? "Order"
                        : "Orders") +
                      ""
                    }
                    actionIcon={<CancelOrdersIcon className="cancel" />}
                    Orders={this.state.filtration.SelectedOrders}
                  />
                ),
              });
            }}
          >
            <CancelOrdersIcon /> Cancel
          </Button>
        </>
      );
    }
  };

  CheckOrderStatusIsValidForRelease = () => {
    let value = OrderStatusEnum.CheckOrderStatusIsValidForRelease(
      this.state.filtration.SelectedOrders
    );
    // let value = { success: true, message: null };

    if (value.success == true) {
      return (
        <>
          <Button
            id="Release-Stock-to-BJS-Action"
            className={"order-action-button "}
            onClick={() => {
              // if (value.message) {
              //   this.ShowError(value.message);
              // } else
              this.setState({
                actionButtonValue: (
                  <OrderActionHelper
                    action="Release"
                    actionTitle={"Release Stock to BJS"}
                    actionIcon={<DispatchStockToBjsIcon />}
                    Orders={this.state.filtration.SelectedOrders}
                    onSuccessClose={() => {
                      this.RefreshData();
                    }}
                    onCancelClose={() => {
                      this.setState({ actionButtonValue: null });
                    }}
                  />
                ),
              });
            }}
          >
            <DispatchStockToBjsIcon /> Release Stock to BJS
          </Button>
        </>
      );
    }
  };
  // On CheckBoxOnSelect
  CheckOrderStatusIsValidForCreate = () => {
    let value = OrderStatusEnum.CheckOrderStatusIsValidForCreate(
      this.state.filtration.SelectedOrders
    );
    // let value = { success: true, message: null };

    if (value.success == true) {
      return (
        <>
          <Button
            id="Dispatch-Orders-to-BJS-Action"
            className={"order-action-button "}
            onClick={() => {
              // if (value.message) {
              //   this.ShowError(value.message);
              // } else
              this.setState({
                actionButtonValue: (
                  <OrderActionHelper
                    action="Create"
                    onSuccessClose={() => {
                      this.RefreshData();
                    }}
                    onCancelClose={() => {
                      this.setState({ actionButtonValue: null });
                    }}
                    actionTitle={
                      "Dispatch " +
                      (this.state.filtration.SelectedOrders.length == 1
                        ? "Order"
                        : "Orders") +
                      " to BJS"
                    }
                    actionIcon={<DispatchOrdersToBjsIcon />}
                    Orders={this.state.filtration.SelectedOrders}
                  />
                ),
              });
            }}
          >
            <DispatchOrdersToBjsIcon /> Dispatch{" "}
            {this.state.filtration.SelectedOrders.length == 1
              ? "Order"
              : "Orders"}{" "}
            to BJS
          </Button>
        </>
      );
    }
  };

  // On CheckBoxOnSelect
  CheckOrderStatusIsValidForGenerateLabels = () => {
    let value = OrderStatusEnum.CheckOrderStatusIsValidForGenerateLabels(
      this.state.filtration.SelectedOrders
    );
    // let value = { success: true, message: null };

    if (value.success == true) {
      return (
        <>
          <Button
            id="Generate-Labels-Action"
            className={"order-action-button "}
            onClick={() => {
              // if (value.message) {
              //   this.ShowError(value.message);
              // } else {
              this.setState({
                actionButtonValue: (
                  <OrderActionHelper
                    action="Label"
                    onSuccessClose={() => {
                      this.RefreshData();
                    }}
                    onCancelClose={() => {
                      this.setState({ actionButtonValue: null });
                    }}
                    actionTitle={"Generate Labels"}
                    actionIcon={<GenerateLabelsIcon />}
                    Orders={this.state.filtration.SelectedOrders}
                  />
                ),
              });
              // }
            }}
          >
            <GenerateLabelsIcon /> Generate Labels
          </Button>
        </>
      );
    }
  };

  ShowError = (message) => {
    this.setState({
      actionButtonValue: (
        <APIResponsePopupHelper
          result={{ success: false, message: message }}
          successClose={() => {}}
          failureClose={() => {
            this.setState({ actionButtonValue: null });
          }}
        />
      ),
    });
  };

  // On CheckBoxOnSelect
  CheckBoxOnSelect = (order) => {
    let filtration = this.state.filtration;
    filtration.SelectedOrders.push(order);
    this.setState({ filtration: filtration });
  };

  CheckBoxOnUnselect = (order) => {
    let filtration = this.state.filtration;

    var myArr = filtration.SelectedOrders;
    var index = myArr.findIndex(function (o) {
      return o.id === order.id;
    });
    if (index !== -1) myArr.splice(index, 1);

    filtration.SelectedOrders = myArr;
    this.setState({ filtration: filtration });
  };

  RefreshData = () => {
    this.StartLoader();
    this.setState({ actionButtonValue: null });
    let filtration = this.state.filtration;

    filtration.isAllChecked = false;
    filtration.SelectedOrders = [];
    this.setState({ filtration: filtration, errorAlert: null });
    this.CheckboxSelection([]);

    this.ApiRequest();
  };

  clearFilter = (request = true) => {
    let filtration = this.state.filtration;
    if (filtration.searchValue) {
      filtration.status = OrderTabEnum.PENDING();
      filtration.orderStatus = OrderTabEnum.PENDING();
      filtration.clickedOrderStatusId = OrderTabEnum.PENDING();
    }
    filtration.start = 0;
    filtration.limit = 30;
    filtration.isOrderProduct = "false";
    filtration.orderByKey = "Updated";
    filtration.searchValueTypedOnly = "";
    filtration.searchValue = "";
    filtration.order = "DESC";
    filtration.isAllChecked = false;
    filtration.searchKey = "";
    filtration.SortToDate = "";
    filtration.SelectedOrders = [];
    filtration.SortFromDate = "";
    filtration.clickedOrderStatusId = OrderTabEnum.PENDING();
    filtration.sorting = "Updated-DESC";
    filtration.quickFilter = [];
    this.setState({ filtration: filtration, errorAlert: null });
    if (request) {
      this.StartLoader();
      this.ApiRequest();
    }
  };
  CheckboxSelection = (params) => {
    if (params.length > 0) {
      this.props.setFooterMargin(50);

      let orders_no = [];

      let status_id = 0;
      this.state.rows.forEach((row) => {
        if (params.includes(row.id)) {
          orders_no.push(row.order_number);
          if (row.status_id > status_id) status_id = row.status_id;
        }
      });
    } else {
      this.props.setFooterMargin(0);
    }
  };

  // Call ApiRequest Datatable Load
  ApiRequest = (url = "") => {
    url = `?start=${encodeURIComponent(
      this.state.filtration.start
    )}&limit=${encodeURIComponent(
      this.state.filtration.limit
    )}&isOrderProduct=${encodeURIComponent(
      this.state.filtration.isOrderProduct
    )}&orderByKey=${encodeURIComponent(
      this.state.filtration.orderByKey
    )}&order=${encodeURIComponent(
      this.state.filtration.order
    )}&searchValue=${encodeURIComponent(
      this.state.filtration.searchValue.replace("#", "")
    )}&status_id=${encodeURIComponent(
      this.state.filtration.orderStatus
    )}&quick_filter=${encodeURI(
      encodeURIComponent(this.state.filtration.quickFilter)
    )}&SortFromDate=${encodeURIComponent(
      this.state.filtration.SortFromDate
    )}&SortToDate=${encodeURIComponent(
      this.state.filtration.SortToDate
    )}&sorting=${encodeURIComponent(
      this.state.filtration.sorting
    )}&lastNMonthOrder=${encodeURIComponent(
      this.state.filtration.lastNMonthOrder
    )}`;
    (async () => {
      APIService.GET(AppSettings.GetOrdersAPI + url)
        .then((response) => response.json())
        .then((response) => {
          if (response.success == true) {
            let recordsCount = {
              pending: response.result.data.pending,
              completed: response.result.data.completed,
              cancelled: response.result.data.cancelled,
              exception: response.result.data.exception,
              active: response.result.data.active,
              queued: response.result.data.queued,
            };
            document.getElementById("total-orders-count-nav").innerHTML =
              recordsCount.pending +
              recordsCount.completed +
              recordsCount.cancelled +
              recordsCount.exception +
              recordsCount.active +
              recordsCount.queued;
            this.setState({
              total_record: response.result.total_record,
              rows: response.result.data.orders,
              recordsCount: recordsCount,
              showTotalRecord: response.result.data.filtered_records,
              loading: false,
              refreshBTN: false,
            });
            this.setState({
              loading: false,
              refreshBTN: false,
            });
          } else {
            this.setState({
              errorAlert: <ApiResponseError response={response} />,
              loading: false,
              refreshBTN: false,
            });
          }
        })
        .catch(() => {
          this.setState({
            errorAlert: (
              <ApiResponseError
                response={{
                  message:
                    process.env.REACT_APP_DEFAULT_INTERNAL_SERVER_MESSAGE,
                }}
              />
            ),
            loading: false,
          });
        });
    })();
  };

  // Start Loader And Empty Table
  StartLoader = (is_empty_datatable = true) => {
    if (is_empty_datatable) this.setState({ rows: [], loading: true });
    else this.setState({ loading: true });
  };
  // Call when refresh the page
  componentDidMount() {
    this.StartLoader();
    this.ApiRequest();
    this.setState({
      loading: true,
      refreshBTN: false,
    });
  }

  closeAlertDialog = () => {
    this.setState({
      falseActionPopup: null,
    });
  };

  productInfo = (
    param,
    index,
    tabIndexing = OrderPopupTabIndexEnum.OVERVIEW()
  ) => {
    let filtration = Object.assign({}, this.state.filtration);
    filtration.start = filtration.start + index - 1;
    this.setState({
      popUpclickedOrder: (
        <OrderDialogBox
          order={param}
          tabIndex={tabIndexing}
          handleCloseOrderDetail={() => {
            this.setState({
              popUpclickedOrder: "",
            });
            this.StartLoader();
            this.ApiRequest();
          }}
          filtration={filtration}
        />
      ),
    });
  };

  // handleCloseOrderDetail = () => {

  // };

  // On Page change
  handlePageChange = (start) => {
    let filtration = this.state.filtration;
    filtration.start = start;
    filtration.SelectedOrders = [];
    filtration.isAllChecked = false;

    this.setState({ filtration: filtration, errorAlert: null });
    this.StartLoader();
    this.ApiRequest();
  };

  // On Page change
  quickFilters = (event, value, name) => {
    let filtration = this.state.filtration;
    if (value) filtration.quickFilter.push(name);
    else {
      let index = filtration.quickFilter.indexOf(name);
      if (index > -1) {
        filtration.quickFilter.splice(index, 1);
      }
    }
    filtration.start = 0;
    filtration.SelectedOrders = [];
    filtration.isAllChecked = false;

    this.setState({ filtration: filtration, errorAlert: null });
    this.StartLoader();
    this.ApiRequest();
  };

  handlePageSizeChange = (params) => {
    let filtration = this.state.filtration;
    filtration.start = 0;
    filtration.isAllChecked = false;
    filtration.SelectedOrders = [];

    filtration.limit = params.target.value;
    this.setState({
      filtration: filtration,
      errorAlert: null,
      actionButtonValue: null,
    });

    this.StartLoader();
    this.ApiRequest();
  };
  inputSearch = () => {
    this.setState({ rows: [], loading: true, errorAlert: null });

    (async () => {
      this.ApiRequest(
        `?start=${this.state.filtration.start}&limit=${this.state.filtration.limit}&isOrderProduct=${this.state.filtration.isOrderProduct}&orderByKey=${this.state.filtration.orderByKey}&order=${this.state.filtration.order}&searchValue=${this.state.filtration.searchValue}`
      );
    })();
  };
  handleSortModelChange = (params) => {
    this.setState({ rows: [], loading: true });

    (async () => {
      let filtration = this.state.filtration;
      filtration.orderByKey = params[0].field;
      filtration.order = params[0].sort;

      this.setState({
        sortModel: params,
        filtration: filtration,
        errorAlert: null,
      });
      this.ApiRequest(
        `?start=${0}&limit=${this.state.pageSize}&isOrderProduct=${
          this.state.filtration.isOrderProduct
        }&orderByKey=${params[0].field}&order=${params[0].sort}`
      );
    })();
  };

  SearchBox = () => {
    return (
      <SearchBarHelper
        placeholder={
          "Search for an order number, customer name, address or post code..."
        }
        value={this.state.filtration.searchValueTypedOnly}
        onChange={(event) => {
          const { value } = event.target;
          let filtration = this.state.filtration;
          filtration.searchValueTypedOnly = value;
          this.setState({ filtration: filtration, errorAlert: null });
        }}
        onSubmit={() => {
          let filtration = this.state.filtration;
          filtration.searchValue = filtration.searchValueTypedOnly;
          if (filtration.searchValueTypedOnly != "") {
            filtration.status = OrderTabEnum.ALL();
            filtration.orderStatus = OrderTabEnum.ALL();
            filtration.clickedOrderStatusId = OrderTabEnum.ALL();
          }
          if (
            filtration.searchValueTypedOnly == "" &&
            filtration.status == OrderTabEnum.ALL()
          ) {
            filtration.status = OrderTabEnum.PENDING();
            filtration.orderStatus = OrderTabEnum.PENDING();
            filtration.clickedOrderStatusId = OrderTabEnum.PENDING();
          }
          filtration.start = 0;
          // filtration.SelectedOrders = [];
          // filtration.isAllChecked= false;

          this.setState({ filtration: filtration, errorAlert: null });
          this.StartLoader();
          this.ApiRequest();
        }}
      />
    );
  };
  render() {
    const { classes } = this.props;
    return (
      <>
        {this.state.errorAlert}
        {this.state.popUpclickedOrder}
        <Grid container spacing={3} id="order-page">
          <Grid item xs={12} sm={12} md={2} className="page-title-grid">
            <h1 className="pageTitle">ORDERS</h1>
            <div className="search-box-mobile">{this.SearchBox()}</div>
          </Grid>
          <Grid item md={8} style={{ maxWidth: "100%" }}>
            <Paper square elevation={0}>
              <Tabs
                variant="scrollable"
                scrollButtons="auto"
                indicatorColor="primary"
                textColor="primary"
                value={this.state.filtration.status}
                className="tab-panel"
                onChange={(event, newValue) => {
                  this.clearFilter(false);
                  let filtration = this.state.filtration;
                  filtration.status = newValue;
                  filtration.orderStatus = newValue;
                  filtration.clickedOrderStatusId = newValue;
                  this.setState({
                    filtration: filtration,
                    errorAlert: null,
                  });
                  this.StartLoader();
                  this.ApiRequest();
                }}
              >
                <Tab
                  value={OrderTabEnum.PENDING()}
                  className={"pending-tab"}
                  icon={
                    <span>
                      Pending{" "}
                      <span className="count">
                        {" "}
                        {this.state.recordsCount.pending}
                      </span>
                    </span>
                  }
                />
                <Tab
                  value={OrderTabEnum.QUEUED()}
                  className={"queued-tab"}
                  icon={
                    <span>
                      Queued{" "}
                      <span className="count">
                        {" "}
                        {this.state.recordsCount.queued}
                      </span>
                    </span>
                  }
                />
                <Tab
                  value={OrderTabEnum.ACTIVE()}
                  className={"active-tab"}
                  icon={
                    <span>
                      Active{" "}
                      <span className="count">
                        {" "}
                        {this.state.recordsCount.active}
                      </span>
                    </span>
                  }
                />
                <Tab
                  value={OrderTabEnum.EXCEPTION()}
                  className={"exception-tab"}
                  icon={
                    <span>
                      Exceptions{" "}
                      <span className="count">
                        {" "}
                        {this.state.recordsCount.exception}
                      </span>
                    </span>
                  }
                />
                <Tab
                  value={OrderTabEnum.COMPLETED()}
                  className={"completed-tab"}
                  icon={
                    <span>
                      Completed{" "}
                      <span className="count">
                        {" "}
                        {this.state.recordsCount.completed}
                      </span>
                    </span>
                  }
                />
                <Tab
                  value={OrderTabEnum.CANCELLED()}
                  className={"cancelled-tab"}
                  icon={
                    <span>
                      Cancelled{" "}
                      <span className="count">
                        {" "}
                        {this.state.recordsCount.cancelled}
                      </span>
                    </span>
                  }
                />
                {this.state.filtration.searchValue && (
                  <Tab
                    value={OrderTabEnum.ALL()}
                    className={"cancelled-tab"}
                    icon={
                      <span>
                        Filtered{" "}
                        <span className="count">
                          {" "}
                          {this.state.showTotalRecord}
                        </span>
                      </span>
                    }
                  />
                )}
              </Tabs>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={2} className="search-box-grid">
            {this.SearchBox()}
          </Grid>
          <Grid className="quick-filter" container>
            <Grid item md={7} className="quick-filter-md-9">
              {this.state.filtration.orderStatus != OrderTabEnum.COMPLETED() &&
                this.state.filtration.searchValue == "" && (
                  <FormControlLabel
                    className="QuickFiltration-heading ml-10"
                    control={<Checkbox className="d-none" />}
                    label="Filters"
                  />
                )}
              {this.state.filtration.orderStatus ==
                OrderTabEnum.CANCELLED() && (
                <div className="quick-filter-checkbox">
                  <CheckBoxHelper
                    id="quick-filter-deleted"
                    onChange={(event, newVal) => {
                      this.quickFilters(event, newVal, "deleted");
                    }}
                    checked={
                      this.state.filtration.quickFilter.includes("deleted") ===
                      true
                        ? true
                        : false
                    }
                  />
                  <label htmlFor="quick-filter-deleted"> Deleted</label>
                </div>
              )}
              {this.state.filtration.orderStatus ==
                OrderTabEnum.CANCELLED() && (
                <div className="quick-filter-checkbox">
                  <CheckBoxHelper
                    id="quick-filter-cancelled"
                    onChange={(event, newVal) => {
                      this.quickFilters(event, newVal, "cancelled");
                    }}
                    checked={
                      this.state.filtration.quickFilter.includes(
                        "cancelled"
                      ) === true
                        ? true
                        : false
                    }
                  />
                  <label htmlFor="quick-filter-cancelled"> Cancelled</label>
                </div>
              )}
              {this.state.filtration.orderStatus == OrderTabEnum.PENDING() && (
                <div className="quick-filter-checkbox">
                  <CheckBoxHelper
                    id="quick-filter-new"
                    onChange={(event, newVal) => {
                      this.quickFilters(event, newVal, "new");
                    }}
                    checked={
                      this.state.filtration.quickFilter.includes("new") === true
                        ? true
                        : false
                    }
                  />
                  <label htmlFor="quick-filter-new"> New</label>
                </div>
              )}
              {this.state.filtration.orderStatus == OrderTabEnum.PENDING() && (
                <div className="quick-filter-checkbox">
                  <CheckBoxHelper
                    id="quick-filter-created"
                    onChange={(event, newVal) => {
                      this.quickFilters(event, newVal, "created");
                    }}
                    checked={
                      this.state.filtration.quickFilter.includes("created") ===
                      true
                        ? true
                        : false
                    }
                  />
                  <label htmlFor="quick-filter-created"> Pending Release</label>
                </div>
              )}

              {this.state.filtration.orderStatus == OrderTabEnum.QUEUED() && (
                <div className="quick-filter-checkbox">
                  <CheckBoxHelper
                    id="quick-filter-marked-created"
                    onChange={(event, newVal) => {
                      this.quickFilters(event, newVal, "marked-created");
                    }}
                    checked={
                      this.state.filtration.quickFilter.includes(
                        "marked-created"
                      ) === true
                        ? true
                        : false
                    }
                  />
                  <label htmlFor="quick-filter-marked-created"> Created</label>
                </div>
              )}
              {this.state.filtration.orderStatus == OrderTabEnum.QUEUED() && (
                <div className="quick-filter-checkbox">
                  <CheckBoxHelper
                    id="quick-filter-marked-cancelled"
                    onChange={(event, newVal) => {
                      this.quickFilters(event, newVal, "marked-cancelled");
                    }}
                    checked={
                      this.state.filtration.quickFilter.includes(
                        "marked-cancelled"
                      ) === true
                        ? true
                        : false
                    }
                  />
                  <label htmlFor="quick-filter-marked-cancelled">
                    {" "}
                    Cancelled
                  </label>
                </div>
              )}
              {this.state.filtration.orderStatus == OrderTabEnum.QUEUED() && (
                <div className="quick-filter-checkbox">
                  <CheckBoxHelper
                    id="quick-filter-marked-deleted"
                    onChange={(event, newVal) => {
                      this.quickFilters(event, newVal, "marked-deleted");
                    }}
                    checked={
                      this.state.filtration.quickFilter.includes(
                        "marked-deleted"
                      ) === true
                        ? true
                        : false
                    }
                  />
                  <label htmlFor="quick-filter-marked-deleted"> Deleted</label>
                </div>
              )}
              {this.state.filtration.orderStatus == OrderTabEnum.QUEUED() && (
                <div className="quick-filter-checkbox">
                  <CheckBoxHelper
                    id="quick-filter-marked-released"
                    onChange={(event, newVal) => {
                      this.quickFilters(event, newVal, "marked-released");
                    }}
                    checked={
                      this.state.filtration.quickFilter.includes(
                        "marked-released"
                      ) === true
                        ? true
                        : false
                    }
                  />
                  <label htmlFor="quick-filter-marked-released">
                    {" "}
                    Released
                  </label>
                </div>
              )}
              {this.state.filtration.orderStatus ==
                OrderTabEnum.EXCEPTION() && (
                <div className="quick-filter-checkbox">
                  <CheckBoxHelper
                    id="quick-filter-exception-created"
                    onChange={(event, newVal) => {
                      this.quickFilters(event, newVal, "exception-created");
                    }}
                    checked={
                      this.state.filtration.quickFilter.includes(
                        "exception-created"
                      ) === true
                        ? true
                        : false
                    }
                  />
                  <label htmlFor="quick-filter-exception-created">
                    {" "}
                    Created
                  </label>
                </div>
              )}
              {this.state.filtration.orderStatus ==
                OrderTabEnum.EXCEPTION() && (
                <div className="quick-filter-checkbox">
                  <CheckBoxHelper
                    id="quick-filter-exception-cancelled"
                    onChange={(event, newVal) => {
                      this.quickFilters(event, newVal, "exception-cancelled");
                    }}
                    checked={
                      this.state.filtration.quickFilter.includes(
                        "exception-cancelled"
                      ) === true
                        ? true
                        : false
                    }
                  />
                  <label htmlFor="quick-filter-exception-cancelled">
                    {" "}
                    Cancelled
                  </label>
                </div>
              )}
              {this.state.filtration.orderStatus ==
                OrderTabEnum.EXCEPTION() && (
                <div className="quick-filter-checkbox">
                  <CheckBoxHelper
                    id="quick-filter-exception-deleted"
                    onChange={(event, newVal) => {
                      this.quickFilters(event, newVal, "exception-deleted");
                    }}
                    checked={
                      this.state.filtration.quickFilter.includes(
                        "exception-deleted"
                      ) === true
                        ? true
                        : false
                    }
                  />
                  <label htmlFor="quick-filter-exception-deleted">
                    {" "}
                    Deleted
                  </label>
                </div>
              )}
              {this.state.filtration.orderStatus ==
                OrderTabEnum.EXCEPTION() && (
                <div className="quick-filter-checkbox">
                  <CheckBoxHelper
                    id="quick-filter-exception-released"
                    onChange={(event, newVal) => {
                      this.quickFilters(event, newVal, "exception-released");
                    }}
                    checked={
                      this.state.filtration.quickFilter.includes(
                        "exception-released"
                      ) === true
                        ? true
                        : false
                    }
                  />
                  <label htmlFor="quick-filter-exception-released">
                    {" "}
                    Released
                  </label>
                </div>
              )}
              {this.state.filtration.orderStatus == OrderTabEnum.ACTIVE() && (
                <div className="quick-filter-checkbox">
                  <CheckBoxHelper
                    id="quick-filter-ReleasedToday"
                    onChange={(event, newVal) => {
                      this.quickFilters(event, newVal, "ReleasedToday");
                    }}
                    checked={
                      this.state.filtration.quickFilter.includes(
                        "ReleasedToday"
                      ) === true
                        ? true
                        : false
                    }
                  />
                  <label htmlFor="quick-filter-ReleasedToday">
                    {" "}
                    Released Today
                  </label>
                </div>
              )}
              {this.state.filtration.orderStatus == OrderTabEnum.ACTIVE() && (
                <div className="quick-filter-checkbox">
                  <CheckBoxHelper
                    id="quick-filter-ScheduledToday"
                    onChange={(event, newVal) => {
                      this.quickFilters(event, newVal, "ScheduledToday");
                    }}
                    checked={
                      this.state.filtration.quickFilter.includes(
                        "ScheduledToday"
                      ) === true
                        ? true
                        : false
                    }
                  />
                  <label htmlFor="quick-filter-ScheduledToday">
                    {" "}
                    Scheduled Today
                  </label>
                </div>
              )}
              {this.state.filtration.orderStatus == OrderTabEnum.ACTIVE() && (
                <div className="quick-filter-checkbox">
                  <CheckBoxHelper
                    id="quick-filter-Unscheduled"
                    onChange={(event, newVal) => {
                      this.quickFilters(event, newVal, "Unscheduled");
                    }}
                    checked={
                      this.state.filtration.quickFilter.includes(
                        "Unscheduled"
                      ) === true
                        ? true
                        : false
                    }
                  />
                  <label htmlFor="quick-filter-Unscheduled"> Unscheduled</label>
                </div>
              )}
              {this.state.filtration.orderStatus ==
                OrderTabEnum.EXCEPTION() && (
                <div className="quick-filter-checkbox">
                  <CheckBoxHelper
                    id="quick-filter-On-Hold"
                    onChange={(event, newVal) => {
                      this.quickFilters(event, newVal, "On-Hold");
                    }}
                    checked={
                      this.state.filtration.quickFilter.includes("On-Hold") ===
                      true
                        ? true
                        : false
                    }
                  />
                  <label htmlFor="quick-filter-On-Hold"> On-Hold</label>
                </div>
              )}
              {this.state.filtration.orderStatus == OrderTabEnum.ACTIVE() && (
                <div className="datePickers">
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    selected={this.state.filtration.SortFromDate}
                    onChange={(date) => {
                      let filtration = this.state.filtration;
                      filtration.start = 0;
                      filtration.SortFromDate = date ? date : "";
                      this.setState({
                        filtration: filtration,
                        errorAlert: null,
                      });
                      this.StartLoader();
                      this.ApiRequest();
                    }}
                    placeholderText="From Date"
                    className="datePicker"
                    maxDate={this.state.filtration.SortToDate}
                  >
                    <div
                      style={{
                        margin: "10px",
                        textAlign: "center",
                        fontWeight: "800",
                      }}
                    >
                      Created From
                    </div>
                  </DatePicker>

                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    selected={this.state.filtration.SortToDate}
                    onChange={(date) => {
                      let filtration = this.state.filtration;
                      filtration.start = 0;
                      filtration.SortToDate = date ? date : "";
                      this.setState({
                        filtration: filtration,
                        errorAlert: null,
                      });
                      this.StartLoader();
                      this.ApiRequest();
                    }}
                    placeholderText="To Date"
                    className="datePicker"
                    minDate={this.state.filtration.SortFromDate}
                    maxDate={new Date()}
                  >
                    <div
                      style={{
                        margin: "10px",
                        textAlign: "center",
                        fontWeight: "800",
                      }}
                    >
                      Created To
                    </div>
                  </DatePicker>
                </div>
              )}
            </Grid>
            <Grid item md={5} className={"quick-filter-md-3"}>
              <FormGroup row>
                <FormLabel component="legend" className="per-page">
                  Per page
                </FormLabel>
                <Select
                  variant="outlined"
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  className="PageNo"
                  IconComponent={() => (
                    <ExpandMoreIcon className="dropdown-arrow  MuiSelect-icon MuiSelect-iconOutlined" />
                  )}
                  value={this.state.filtration.limit}
                  onChange={this.handlePageSizeChange}
                >
                  <MenuItem value={30}>30</MenuItem>
                  <MenuItem value={60}>60</MenuItem>
                  <MenuItem value={90}>90</MenuItem>
                  <MenuItem value={120}>120</MenuItem>
                </Select>
                <SortByIcon />
                <Select
                  className="PageNo sorting-select max-width-120"
                  variant="outlined"
                  IconComponent={() => (
                    <ExpandMoreIcon className="dropdown-arrow  MuiSelect-icon MuiSelect-iconOutlined" />
                  )}
                  value={this.state.filtration.sorting}
                  onChange={(params) => {
                    let filtration = this.state.filtration;
                    filtration.start = 0;
                    filtration.sorting = params.target.value;
                    filtration.SelectedOrders = [];
                    filtration.isAllChecked = false;

                    if (filtration.sorting.match(/-ASC/g)) {
                      filtration.orderByKey = filtration.sorting.replace(
                        "-ASC",
                        ""
                      );
                      filtration.order = "ASC";
                    } else {
                      filtration.orderByKey = filtration.sorting.replace(
                        "-DESC",
                        ""
                      );
                      filtration.order = "DESC";
                    }

                    this.setState({ filtration: filtration, errorAlert: null });
                    this.StartLoader();
                    this.ApiRequest();
                  }}
                >
                  <MenuItem className="sorting" value={"Updated-ASC"}>
                    Updated&nbsp;
                    <img src="images/ascending-sort.png" />
                  </MenuItem>
                  <MenuItem className="sorting" value={"Updated-DESC"}>
                    Updated&nbsp;
                    <img src="images/descending-sort.png" />
                  </MenuItem>
                  <MenuItem className="sorting" value={"Ordered-ASC"}>
                    Ordered&nbsp;
                    <img src="images/ascending-sort.png" />
                  </MenuItem>
                  <MenuItem className="sorting" value={"Ordered-DESC"}>
                    Ordered&nbsp;
                    <img src="images/descending-sort.png" />
                  </MenuItem>
                  <MenuItem className="sorting" value={"Created-ASC"}>
                    Created&nbsp;
                    <img src="images/ascending-sort.png" />
                  </MenuItem>
                  <MenuItem className="sorting" value={"Created-DESC"}>
                    Created&nbsp;
                    <img src="images/descending-sort.png" />
                  </MenuItem>
                  {this.state.filtration.orderStatus ==
                    OrderTabEnum.ACTIVE() && (
                    <MenuItem className="sorting" value={"Schedule-ASC"}>
                      Schedule&nbsp;
                      <img src="images/ascending-sort.png" />
                    </MenuItem>
                  )}
                  {this.state.filtration.orderStatus ==
                    OrderTabEnum.ACTIVE() && (
                    <MenuItem className="sorting" value={"Schedule-DESC"}>
                      Schedule&nbsp;
                      <img src="images/descending-sort.png" />
                    </MenuItem>
                  )}

                  {this.state.filtration.orderStatus ==
                    OrderTabEnum.COMPLETED() && (
                    <MenuItem className="sorting" value={"Completed-ASC"}>
                      Completed&nbsp;
                      <img src="images/ascending-sort.png" />
                    </MenuItem>
                  )}
                  {this.state.filtration.orderStatus ==
                    OrderTabEnum.COMPLETED() && (
                    <MenuItem className="sorting" value={"Completed-DESC"}>
                      Completed&nbsp;
                      <img src="images/descending-sort.png" />
                    </MenuItem>
                  )}
                  {this.state.filtration.orderStatus ==
                    OrderTabEnum.CANCELLED() && (
                    <MenuItem className="sorting" value={"Cancelled-ASC"}>
                      Cancelled&nbsp;
                      <img src="images/ascending-sort.png" />
                    </MenuItem>
                  )}
                  {this.state.filtration.orderStatus ==
                    OrderTabEnum.CANCELLED() && (
                    <MenuItem className="sorting" value={"Cancelled-DESC"}>
                      Cancelled&nbsp;
                      <img src="images/descending-sort.png" />
                    </MenuItem>
                  )}
                  {this.state.filtration.orderStatus ==
                    OrderTabEnum.EXCEPTION() && (
                    <MenuItem className="sorting" value={"Exception-ASC"}>
                      Exception&nbsp;
                      <img src="images/ascending-sort.png" />
                    </MenuItem>
                  )}
                  {this.state.filtration.orderStatus ==
                    OrderTabEnum.EXCEPTION() && (
                    <MenuItem className="sorting" value={"Exception-DESC"}>
                      Exception&nbsp;
                      <img src="images/descending-sort.png" />
                    </MenuItem>
                  )}
                </Select>
                <Select
                  className="PageNo max-width-120"
                  variant="outlined"
                  IconComponent={() => (
                    <ExpandMoreIcon className="dropdown-arrow  MuiSelect-icon MuiSelect-iconOutlined" />
                  )}
                  value={this.state.filtration.lastNMonthOrder}
                  onChange={(params) => {
                    let filtration = this.state.filtration;
                    filtration.start = 0;
                    filtration.SelectedOrders = [];
                    filtration.isAllChecked = false;

                    filtration.lastNMonthOrder = params.target.value;
                    localStorage.setItem(
                      "lastNMonthOrder",
                      params.target.value
                    );

                    this.setState({ filtration: filtration, errorAlert: null });
                    this.StartLoader();
                    this.ApiRequest();
                  }}
                >
                  <MenuItem value={1}>Last 1 Month Orders</MenuItem>
                  <MenuItem value={3}>Last 3 Months Orders</MenuItem>
                  <MenuItem value={6}>Last 6 Months Orders</MenuItem>
                  <MenuItem value={9}>Last 9 Months Orders</MenuItem>
                </Select>
              </FormGroup>
            </Grid>
          </Grid>
          <Grid
            className="mr-8 ml-8 count-pagination-searched-result"
            container
          >
            <Grid item md={8} className="">
              <Typography className="record-count">
                {this.state.showTotalRecord}{" "}
                {this.state.showTotalRecord < 1 ? "Result" : "Results"}
              </Typography>

              {this.state.filtration.searchValue != "" && (
                <>
                  <a
                    color="primary"
                    className={"btn-clear"}
                    onClick={() => {
                      let filtration = this.state.filtration;

                      filtration.searchValue = "";
                      filtration.searchValueTypedOnly = "";
                      filtration.status = OrderTabEnum.PENDING();
                      filtration.orderStatus = OrderTabEnum.PENDING();
                      filtration.clickedOrderStatusId = OrderTabEnum.PENDING();

                      this.setState({
                        filtration: filtration,
                        errorAlert: null,
                      });
                      this.StartLoader();
                      this.ApiRequest();
                    }}
                  >
                    Clear Search
                  </a>
                  <span className={"btn-search-value"}>
                    Searched Key - {this.state.filtration.searchValue}
                  </span>
                </>
              )}
              {(this.state.filtration.quickFilter.length > 0 ||
                this.state.actionButtonValue) && (
                <a
                  color="primary"
                  className={"btn-clear"}
                  onClick={this.clearFilter}
                >
                  Clear Filters
                </a>
              )}
            </Grid>
            <Grid item md={4} className={"pagination"}>
              <Pagination
                count={this.state.showTotalRecord}
                start={this.state.filtration.start}
                limit={this.state.filtration.limit}
                handlePageChange={this.handlePageChange}
              />
            </Grid>
          </Grid>
          <Grid
            item
            md={12}
            className={classes.dataGrid}
            // style={{ minHeight: '300px' }}
          >
            <TableContainer
              classes={{ root: classes.customTableContainer }}
              className="order-table"
            >
              <Table stickyHeader>
                <TableHead className="">
                  <TableRow>
                    <TableCell>
                      <CheckBoxHelper
                        onChange={(event, newVal) => {
                          let filtration = this.state.filtration;
                          let SelectedOrders = [];

                          if (newVal) {
                            !this.state.loading &&
                              this.state.rows.length > 0 &&
                              this.state.rows.map((row) => {
                                let isEditable =
                                  OrderStatusEnum.CheckOrderStatusIsCheckBoxOperation(
                                    [row]
                                  );

                                if (isEditable.success) {
                                  SelectedOrders.push(row);
                                }
                              });
                            filtration.isAllChecked = true;
                          } else {
                            filtration.isAllChecked = false;
                          }
                          filtration.SelectedOrders = SelectedOrders;
                          this.setState({ filtration: filtration });
                          this.CheckboxSelection(
                            this.state.filtration.SelectedOrders
                          );
                        }}
                        checked={this.state.filtration.isAllChecked}
                      />
                    </TableCell>
                    <TableCell>ORDER NUM</TableCell>

                    <TableCell>
                      CREATED{" "}
                      <ShopifyIcon
                        className="created-icon"
                        style={{ position: "absolute" }}
                      />
                    </TableCell>
                    <TableCell>
                      CREATED <BjsIcon className="created-icon" />
                    </TableCell>
                    {this.state.filtration.orderStatus !=
                      OrderTabEnum.PENDING() &&
                      this.state.filtration.orderStatus !=
                        OrderTabEnum.EXCEPTION() &&
                      this.state.filtration.orderStatus !=
                        OrderTabEnum.CANCELLED() && (
                        <TableCell>CONSIGNMENT</TableCell>
                      )}

                    {this.state.filtration.orderStatus ==
                      OrderTabEnum.ACTIVE() && <TableCell>SCHEDULE</TableCell>}
                    {this.state.filtration.orderStatus !=
                      OrderTabEnum.EXCEPTION() && (
                      <TableCell>CUSTOMER NAME</TableCell>
                    )}
                    {this.state.filtration.orderStatus ==
                      OrderTabEnum.COMPLETED() && (
                      <TableCell>COMPLETE</TableCell>
                    )}
                    {this.state.filtration.orderStatus ==
                      OrderTabEnum.PENDING() && <TableCell>ADDRESS</TableCell>}
                    {this.state.filtration.orderStatus !=
                      OrderTabEnum.COMPLETED() &&
                      this.state.filtration.orderStatus !=
                        OrderTabEnum.EXCEPTION() &&
                      this.state.filtration.orderStatus !=
                        OrderTabEnum.CANCELLED() && (
                        <TableCell>POST CODE</TableCell>
                      )}
                    {this.state.filtration.orderStatus ==
                      OrderTabEnum.EXCEPTION() && (
                      <TableCell>Exception Message</TableCell>
                    )}
                    {this.state.filtration.orderStatus ==
                      OrderTabEnum.EXCEPTION() && (
                      <TableCell>Exception Date</TableCell>
                    )}
                    {this.state.filtration.orderStatus ==
                      OrderTabEnum.CANCELLED() && (
                      <TableCell>CANCELLED</TableCell>
                    )}
                    {(this.state.filtration.orderStatus ==
                      OrderTabEnum.COMPLETED() ||
                      this.state.filtration.orderStatus ==
                        OrderTabEnum.CANCELLED()) && (
                      <TableCell>ePOD</TableCell>
                    )}
                    <TableCell>STATUS</TableCell>
                    <TableCell>
                      <Button
                        onClick={() => {
                          this.StartLoader();
                          this.setState({ errorAlert: null, refreshBTN: true });
                          this.ApiRequest();
                        }}
                      >
                        <AutorenewIcon
                          style={{
                            color: "white",
                            justifyContent: "space-around",
                          }}
                          className={
                            this.state.refreshBTN === true ? " rotating " : "  "
                          }
                        />
                        <span>
                          {this.state.refreshBTN === true
                            ? " Refreshing Data"
                            : " Refresh "}
                        </span>
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!this.state.loading &&
                    this.state.rows.length > 0 &&
                    this.state.rows.map((row, index) => (
                      <TableRow
                        key={row.id}
                        className={
                          this.state.filtration.SelectedOrders.includes(row) ===
                          true
                            ? "active"
                            : ""
                        }
                      >
                        <TableCell className="check-box-td">
                          {this.CheckOrderStatusIsCheckBoxOperation(row)}
                        </TableCell>
                        <TableCell>{row.order_number}</TableCell>
                        <TableCell>
                          {DateConvertor(row.shopify_created_at)}
                        </TableCell>
                        <TableCell>
                          {DateConvertor(row.order_created_at_bjs)}
                        </TableCell>
                        {this.state.filtration.orderStatus !=
                          OrderTabEnum.PENDING() &&
                          this.state.filtration.orderStatus !=
                            OrderTabEnum.EXCEPTION() &&
                          this.state.filtration.orderStatus !=
                            OrderTabEnum.CANCELLED() && (
                            <TableCell className="link">
                              {row.bjs_carrier_ref && (
                                <ConsignmentNoHelper no={row.bjs_carrier_ref} />
                              )}
                            </TableCell>
                          )}

                        {this.state.filtration.orderStatus ==
                          OrderTabEnum.ACTIVE() && (
                          <TableCell>
                            {DateConvertor(row.schedule_date)}
                          </TableCell>
                        )}

                        {this.state.filtration.orderStatus !=
                          OrderTabEnum.EXCEPTION() && (
                          <TableCell>{`${row.first_name || ""} ${
                            row.middle_name || ""
                          } ${row.last_name || ""}`}</TableCell>
                        )}
                        {this.state.filtration.orderStatus ==
                          OrderTabEnum.COMPLETED() && (
                          <TableCell>
                            {DateConvertor(row.completed_date)}
                          </TableCell>
                        )}

                        {this.state.filtration.orderStatus ==
                          OrderTabEnum.PENDING() && (
                          <TableCell className="address">{`${
                            row.addressline1 != "" && row.addressline1 != null
                              ? row.addressline1 + ", "
                              : ""
                          }${
                            row.addressline2 != "" && row.addressline2 != null
                              ? row.addressline2 + ", "
                              : ""
                          }${
                            row.addressline3 != "" && row.addressline3 != null
                              ? row.addressline3 + ", "
                              : ""
                          }${
                            row.city != "" && row.city != null
                              ? row.city + ", "
                              : ""
                          }${
                            row.state != "" && row.state != null
                              ? row.state + ", "
                              : ""
                          }${
                            row.zipcode != "" && row.zipcode != null
                              ? row.zipcode + ", "
                              : ""
                          }${
                            row.country != "" && row.country != null
                              ? row.country
                              : ""
                          }`}</TableCell>
                        )}
                        {this.state.filtration.orderStatus !=
                          OrderTabEnum.COMPLETED() &&
                          this.state.filtration.orderStatus !=
                            OrderTabEnum.EXCEPTION() &&
                          this.state.filtration.orderStatus !=
                            OrderTabEnum.CANCELLED() && (
                            <TableCell>{row.zipcode}</TableCell>
                          )}

                        {this.state.filtration.orderStatus ==
                          OrderTabEnum.CANCELLED() && (
                          <TableCell>
                            {DateConvertor(row.bjs_cancelled_datetime)}
                          </TableCell>
                        )}

                        {this.state.filtration.orderStatus ==
                          OrderTabEnum.EXCEPTION() && (
                          <TableCell>{row.exception_message}</TableCell>
                        )}

                        {this.state.filtration.orderStatus ==
                          OrderTabEnum.EXCEPTION() && (
                          <TableCell>
                            {DateConvertor(row.exception_date)}
                          </TableCell>
                        )}
                        {(this.state.filtration.orderStatus ==
                          OrderTabEnum.COMPLETED() ||
                          this.state.filtration.orderStatus ==
                            OrderTabEnum.CANCELLED()) && (
                          <TableCell className="link">
                            {row.ePODLink && (
                              <a
                                rel="noreferrer"
                                href={row.ePODLink}
                                target="_blank"
                              >
                                ePOD LINK
                              </a>
                            )}
                          </TableCell>
                        )}
                        <TableCell className="order-status">
                          {<OrderStatus code={row.status_id} />}
                        </TableCell>
                        <TableCell className="order-action">
                          {
                            <div>
                              {row.batch_refrence && row.batch_id && (
                                <Tooltip
                                  arrow
                                  title={row.batch_refrence}
                                  placement="top"
                                >
                                  <IconButton
                                    component="span"
                                    size="small"
                                    className={"text-black "}
                                    // onClick={() => {
                                    //   this.setState({
                                    //     batchOrdersRefrence: row.batch_refrence,
                                    //     batchOrdersId: row.batch_id,
                                    //     batchOrdersCreatedAt: row.batch_created_at,
                                    //   });
                                    // }}
                                  >
                                    <NavDispatchesIcon />
                                  </IconButton>
                                </Tooltip>
                              )}

                              <Button
                                className={"more-info "}
                                component="button"
                                size="small"
                                onClick={() => {
                                  this.productInfo(row, index);
                                }}
                              >
                                MORE INFO
                              </Button>
                              <OrderActionHelper
                                ProductInfo={() => {
                                  this.productInfo(row, index, 2);
                                }}
                                Orders={[row]}
                                onSuccessClose={() => {
                                  this.RefreshData();
                                }}
                                onCancelClose={() => {}}
                              />
                            </div>
                          }
                        </TableCell>
                      </TableRow>
                    ))}
                  {this.state.loading && (
                    <>
                      {Array.from(new Array(10)).map((val, index) => (
                        <TableRow key={index}>
                          <TableCell colSpan={12}>
                            <Skeleton
                              animation="wave"
                              variant="rectangular"
                              height={40}
                              width={"100%"}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </>
                  )}
                  {!this.state.loading && this.state.rows.length == 0 && (
                    <TableRow>
                      <TableCell colSpan={12}>
                        <NoResultHelper />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid
            className="mr-8 ml-8 count-pagination-searched-result"
            container
          >
            <Grid item md={8} className=""></Grid>
            <Grid item md={4} className={"pagination"}>
              <Pagination
                count={this.state.showTotalRecord}
                start={this.state.filtration.start}
                limit={this.state.filtration.limit}
                handlePageChange={this.handlePageChange}
              />
            </Grid>
          </Grid>
          {this.state.filtration.SelectedOrders.length > 0 && (
            <Grid
              container
              spacing={3}
              className="count-pagination-searched-result multi-order-action"
            >
              <Grid item xs={12} sm={12} md={2}>
                <Typography className="record-count">
                  <span>{this.state.filtration.SelectedOrders.length} </span>
                  {this.state.filtration.SelectedOrders.length == 1
                    ? "Order"
                    : "Orders"}{" "}
                  Selected
                </Typography>
                <a
                  color="primary"
                  className={"btn-clear"}
                  onClick={() => {
                    let filtration = this.state.filtration;
                    filtration.SelectedOrders = [];
                    filtration.isAllChecked = false;
                    this.setState({ filtration: filtration });

                    this.CheckboxSelection([]);
                  }}
                >
                  Clear Selection
                </a>
              </Grid>
              <Grid item xs={12} sm={12} md={10} className="order-action">
                <div className="order-action-desktop">
                  <Typography component="span" className="order-action-text">
                    Order Actions:
                  </Typography>
                  {!APIService.GetStoreConfig("create_orders") &&
                    !APIService.GetStoreConfig("batch_orders_auto") && (
                      <>{this.CheckOrderStatusIsValidForCreate()}</>
                    )}

                  {!APIService.GetStoreConfig("released_orders") && (
                    <>{this.CheckOrderStatusIsValidForRelease()}</>
                  )}
                  {this.CheckOrderStatusIsValidForGenerateLabels()}
                  {this.CheckOrderStatusIsValidForCancelOrder()}
                  {this.CheckOrderStatusIsValidForDeleteOrder()}
                </div>
                {this.state.actionButtonValue}
              </Grid>
            </Grid>
          )}
        </Grid>
        {this.state.isOpenOrderDetail}
        <Snackbar
          autoHideDuration={6000}
          open={this.state.alert}
          onClose={() => this.setState({ alert: false })}
        >
          <Alert severity={this.state.alertType}>
            {this.state.alertMessage}
          </Alert>
        </Snackbar>
        {this.state.batchOrdersRefrence && this.state.batchOrdersId && (
          <OrderDialogBox
            batchOrdersRefrence={this.state.batchOrdersRefrence}
            batchOrdersId={this.state.batchOrdersId}
            batchOrdersCreatedAt={this.state.batchOrdersCreatedAt}
            handleCloseOrderDetail={() => {
              this.setState({
                batchOrdersRefrence: null,
                batchOrdersId: null,
                batchOrdersCreatedAt: null,
              });
            }}
          />
        )}
        <AutoReleasePopupHelper RefreshData={this.RefreshData} />
        <SpeedDialTooltipOpen
          selectedOrdersLength={this.state.filtration.SelectedOrders.length}
        />
      </>
    );
  }
}

export default withStyles(styles, { withTheme: true })(OrdersPage);

function SpeedDialTooltipOpen(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      {props.selectedOrdersLength > 0 && (
        <SpeedDial
          ariaLabel="SpeedDial tooltip example"
          className="mobile-orders-actions"
          sx={{ position: "absolute", bottom: 16, right: 16 }}
          icon={<SpeedDialIcon />}
          onClose={handleClose}
          onOpen={handleOpen}
          open={open}
        >
          {document.getElementById("Delete-Orders-Action") && (
            <SpeedDialAction
              key={"Delete-Orders-Action"}
              className=" delete-orders-action"
              icon={<DeleteOrdersIcon />}
              tooltipTitle={"Delete"}
              tooltipOpen
              onClick={() => {
                document.getElementById("Delete-Orders-Action").click();
              }}
            />
          )}
          {document.getElementById("Cancel-Orders-Action") && (
            <SpeedDialAction
              key={"Cancel-Orders-Action"}
              icon={<CancelOrdersIcon />}
              className=" cancel-orders-action"
              tooltipTitle={"Cancel"}
              tooltipOpen
              onClick={() => {
                document.getElementById("Cancel-Orders-Action").click();
              }}
            />
          )}
          {document.getElementById("Release-Stock-to-BJS-Action") && (
            <SpeedDialAction
              key={"Release-Stock-to-BJS-Action"}
              icon={<DispatchStockToBjsIcon />}
              className=" Release-Stock-to-BJS-Action"
              tooltipTitle={"Release Stock to BJS"}
              tooltipOpen
              onClick={() => {
                document.getElementById("Release-Stock-to-BJS-Action").click();
              }}
            />
          )}
          {document.getElementById("Dispatch-Orders-to-BJS-Action") && (
            <SpeedDialAction
              key={"Dispatch-Orders-to-BJS-Action"}
              icon={<DispatchOrdersToBjsIcon />}
              className=" Dispatch-Orders-to-BJS-Action"
              tooltipTitle={
                "Dispatch " +
                (props.selectedOrdersLength == 1 ? "Order" : "Orders") +
                " to BJS"
              }
              tooltipOpen
              onClick={() => {
                document
                  .getElementById("Dispatch-Orders-to-BJS-Action")
                  .click();
              }}
            />
          )}
          {document.getElementById("Generate-Labels-Action") && (
            <SpeedDialAction
              key={"Generate-Labels-Action"}
              icon={<GenerateLabelsIcon />}
              className=" Generate-Labels-Action"
              tooltipTitle={"Generate Labels"}
              tooltipOpen
              onClick={() => {
                document.getElementById("Generate-Labels-Action").click();
              }}
            />
          )}
        </SpeedDial>
      )}
    </>
  );
}

import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  // Paper,
  // Tabs,
  // Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Snackbar,
  Tab,
  Tabs,
  Typography,
  withStyles,
} from "@material-ui/core";
// import { DataGrid } from "@material-ui/data-grid";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import CloseIcon from "@material-ui/icons/Close";
// import AppSettings from "app-settings";
// import "./OrderPopup.scss";
// import DateConvertor from "../helper/core/DateConvertor.jsx";
import NoResultHelper from "../helper/NoResultHelper.jsx";
import CheckBoxHelper from "../helper/CheckBoxHelper.jsx";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import Alert from "@material-ui/lab/Alert";
import OutlinedInput from "@mui/material/OutlinedInput";
import Skeleton from "@mui/material/Skeleton";
import TextField from "@mui/material/TextField";
import AppSettings from "app-settings";
import { AnimatePresence, AnimateSharedLayout, motion } from "framer-motion";
import React, { Component } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import OrderStatusEnum from "../helper/OrderStatusEnum.jsx";
import { ReactComponent as FileCopyIcon } from "../../assets/img/generalIcons_Copy.svg";
import { ReactComponent as NoteAddIcon } from "../../assets/img/ctaIcons_AddNotes.svg";
import { ReactComponent as ExportCsvIcon } from "../../assets/img/ctaIcons_ExportCSV.svg";
import { ReactComponent as GeneralCheckIcon } from "../../assets/img/generalIcons_Check.svg";
import { ReactComponent as BarcodeIcon } from "../../assets/img/generalIcons_BarCode.svg";
import { ReactComponent as GeneralCloseIcon } from "../../assets/img/generalIcons_Close.svg";
import { ReactComponent as DeliveryIcon } from "../../assets/img/generalIcons_Delivery.svg";
// import ApiResponseError from "../helper/ApiResponseError.jsx";
import { ReactComponent as PencilEditIcon } from "../../assets/img/generalIcons_Edit.svg";
import { ReactComponent as NotesIcon } from "../../assets/img/generalIcons_Notes.svg";
import { ReactComponent as OrderIcon } from "../../assets/img/navIcons_Orders.svg";
import {
  ReactComponent as LeftArrow,
  ReactComponent as LeftArrowIcon,
} from "../../assets/img/navigationIcons_LeftArrow.svg";
import {
  ReactComponent as RightArrow,
  ReactComponent as RightArrowIcon,
} from "../../assets/img/navigationIcons_RightArrow.svg";
import APIService from "../../services/APIService.js";
// import APIService from "../../services/APIService.js";
import Export from "../../services/Export.js";
import ApiResponseError from "../helper/ApiResponseError.jsx";
// import NoteAddIcon from "@material-ui/icons/NoteAdd";
import APIResponsePopupHelper from "../helper/APIResponsePopupHelper.jsx";
import ConsignmentNoHelper from "../helper/ConsignmentNoHelper.jsx";
import DateConvertor from "../helper/core/DateConvertor.jsx";
import { LoadingOnPopupHelper } from "../helper/LoadingHelper.jsx";
import OrderEventLoggedBy from "../helper/OrderEventLoggedBy.jsx";
import ProgressBarHelper from "../helper/ProgressBarHelper.jsx";
import "../OrderPopup/OrderPopup.scss";
import MapBoxHelper from "./../helper/MapBoxHelper.jsx";
import OrderStatus from "./../helper/OrderStatus.jsx";

let mapboxHeight = 100;
if (document.getElementById("mapBox-id"))
  mapboxHeight = document.getElementById("mapBox-id").offsetWidth;
const contentHeight = 500;
const styles = () => ({
  mapBoxClass: {
    height: mapboxHeight + "px",
  },
  loaderHeight: {
    minHeight: contentHeight + "px",
  },
  contenHeight: {
    minHeight: contentHeight + "px",
    // overflow: "auto",
    padding: "10px!important",
  },
  fullWidth: {
    width: "100%",
  },
  datagrid: {
    minHeight: contentHeight - 50 + "px",
  },
});

class OrderDialogBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorAlert: null,
      apiResponseMessagePopup: null,
      isOpenOrderDetail: true,
      isLoading: false, // to Discard
      currentOrder: null, // to Discard
      previousOrder: null, // to Discard
      nextOrder: null, // to Discard
      tabIndex: props.tabIndex,
      filtration: props.filtration,
      isOverviewLoaded: false,
      isAuditLogLoaded: false,
      isOrderNotesLoaded: false,
      overviewClassName: "d-none",
      auditLogClassName: "d-none",
      progressBarClassName: "",
      orderNotesClassName: "d-none",
      overviewData: null,
      CopyContent: false,
      isOrderDetailEditable: false,
      orderNotesData: null,
      // currentOrder: null,
      singleDetailEdit: {
        fieldName: null,
        value: null,
      },
      auditLogRows: [],
      auditLogLoading: true,
      editNote: {
        isEditable: false,
        value: null,
      },
      // editAddress: {
      //   isEditable: false,
      //   value: {
      //     addressline1: null,
      //     addressline2: null,
      //     addressline3: null,
      //     city: null,
      //     state: null,
      //     zipcode: null,
      //     country: null,
      //   },
      // },
      auditLogSelectedColumns: [],
    };
    this.ApiRequest = this.ApiRequest.bind(this);
    this.handleCloseOrderDetail = this.handleCloseOrderDetail.bind(this);
    // this.setCurrentOrder = this.setCurrentOrder.bind(this);

    this.tabChanger = this.tabChanger.bind(this);
    // this.AuditLog = this.AuditLog.bind(this);
    // this.OrderNotes = this.OrderNotes.bind(this);
    this.HideAllTabs = this.HideAllTabs.bind(this);
    this.ExportLogCsv = this.ExportLogCsv.bind(this);
    this.handleSingleDetailEdit = this.handleSingleDetailEdit.bind(this);
    this.getServiceValue = this.getServiceValue.bind(this);
    this.AddNotes = this.AddNotes.bind(this);
    // this.setCurrentProduct = this.setCurrentProduct.bind(this);
    this.GetNotes = this.GetNotes.bind(this);
  }

  ExportLogCsv = () => {
    Export(
      APIService.POST(
        AppSettings.GetOrderLog + this.state.currentOrder.id + "/ExportCSV",
        { ids: this.state.auditLogSelectedColumns }
      ),
      "AuditLog.csv"
    );
    this.setState({ auditLogSelectedColumns: [] });
  };

  // On Page changeDownloadCSV
  AddNotes = () => {
    this.setState({ isLoading: true });
    let object = {};
    object.Note = this.state.editNote.value;
    object.CarrierReference = this.state.currentOrder.consignment_no;
    object.order_number = this.state.currentOrder.order_number;
    APIService.POST(
      AppSettings.AddOrderNotes + this.state.currentOrder.id,
      object
    )
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
          this.GetNotes();
          let editNote = Object.assign({}, this.state.editNote);
          editNote.isEditable = false;
          editNote.value = null;
          this.setState({
            editNote: editNote,
            apiResponseMessagePopup: response,
            isLoading: false,
          });
        } else {
          let editNote = Object.assign({}, this.state.editNote);
          editNote.isEditable = true;
          this.setState({
            editNote: editNote,
            apiResponseMessagePopup: response,
            isLoading: false,
          });
        }
      })
      .catch(() => {
        this.setState({
          apiResponseMessagePopup: {
            success: false,
            message: process.env.REACT_APP_DEFAULT_INTERNAL_SERVER_MESSAGE,
          },
        });
      });
  };
  // Call ApiRequest Datatable Load
  ApiRequest = (filtration) => {
    var url = "";
    if (filtration.batch_id)
      url += `&batch_id=${encodeURIComponent(filtration.batch_id)}`;
    if (filtration.orderId)
      url += `&order_id=${encodeURIComponent(filtration.orderId)}`;
    if (filtration.orderByKey)
      url += `&orderByKey=${encodeURIComponent(filtration.orderByKey)}`;
    if (filtration.order)
      url += `&order=${encodeURIComponent(filtration.order)}`;
    if (filtration.searchValue)
      url += `&searchValue=${encodeURIComponent(filtration.searchValue)}`;
    if (filtration.orderStatus)
      url += `&status_id=${encodeURIComponent(filtration.orderStatus)}`;
    if (filtration.quickFilter)
      url += `&quick_filter=${encodeURIComponent(
        encodeURI(filtration.quickFilter)
      )}`;
    if (filtration.SortFromDate)
      url += `&SortFromDate=${encodeURIComponent(filtration.SortFromDate)}`;
    if (filtration.SortToDate)
      url += `&SortToDate=${encodeURIComponent(filtration.SortToDate)}`;
    if (filtration.sorting)
      url += `&sorting=${encodeURIComponent(filtration.sorting)}`;
    if (filtration.lastNMonthOrder)
      url += `&lastNMonthOrder=${encodeURIComponent(
        filtration.lastNMonthOrder
      )}`;

    url = `?start=${encodeURIComponent(
      filtration.start
    )}&limit=3&isOrderProduct=true${url}`;
    (async () => {
      this.setState({ currentOrder: null });
      APIService.GET(AppSettings.GetOrdersAPI + url)
        .then((response) => response.json())
        .then((response) => {
          if (response.success == true) {
            this.setState({
              currentOrder: response.result.data.order,
              previousOrder: response.result.data.prev,
              filtration: filtration,
              nextOrder: response.result.data.next,
              orderId: response.result.data.order.id,
              isAuditLogLoaded: false,
              isOrderNotesLoaded: false,
              orderNotesData: null,
              auditLogRows: [],
              isOrderDetailEditable:
                response.result.data.order.status_id != 2 &&
                response.result.data.order.status_id != 13 &&
                response.result.data.order.status_id != 14,
              loading: false,
            });
            if (this.state.tabIndex == 2) {
              this.GetNotes();
              this.HideAllTabs();
              this.setState({
                orderNotesClassName: null,
              });
            }
            if (this.state.tabIndex == 1) {
              this.AuditLog();
            }
            if (this.state.tabIndex == 0) {
              this.HideAllTabs();
              this.setState({
                overviewClassName: null,
              });
            }
            this.tabChanger(null, this.state.tabIndex);

            // alert(response.result.data.next.id);
          } else {
            this.setState({
              errorAlert: <ApiResponseError response={response} />,
              loading: false,
              refreshBTN: false,
            });
            this.HideAllTabs();

            // this.setState({
            //   apiResponseMessagePopup: response
            // });
          }
        })
        .catch(() => {
          this.setState({
            errorAlert: (
              <ApiResponseError
                response={{
                  message:
                    process.env.REACT_APP_DEFAULT_INTERNAL_SERVER_MESSAGE,
                }}
              />
            ),
            loading: false,
          });
        });
    })();
  };

  getServiceValue = (is_true) => {
    // GeneralCheckIcon
    // GeneralCloseIcon
    if (is_true) return <GeneralCheckIcon className="services-bool true" />;
    else return <GeneralCloseIcon className="services-bool false" />;
  };
  // On Page changeDownloadCSV
  GetNotes = () => {
    APIService.GET(AppSettings.GetOrderNotes + this.state.currentOrder.id)
      .then((response) => response.json())
      .then((response) => {
        this.setState({
          orderNotesData: response.result,
          isOrderNotesLoaded: true,
        });
      })
      .catch(() => {
        this.setState({
          apiResponseMessagePopup: {
            success: false,
            message: process.env.REACT_APP_DEFAULT_INTERNAL_SERVER_MESSAGE,
          },
        });
      });
  };
  HideAllTabs() {
    this.setState({
      overviewClassName: "d-none",
      auditLogClassName: "d-none",
      orderNotesClassName: "d-none",
      progressBarClassName: "d-none",
    });
  }
  tabChanger(event, newValue) {
    this.HideAllTabs();
    switch (newValue) {
      case 0: {
        if (this.state.isOverviewLoaded) {
          this.setState({ overviewClassName: null });
        } else {
          this.setState({
            overviewClassName: null,
          });
        }
        break;
      }
      case 1: {
        if (this.state.isAuditLogLoaded) {
          this.setState({ auditLogClassName: null });
        } else {
          this.AuditLog();
        }
        break;
      }
      case 2: {
        if (this.state.isOrderNotesLoaded) {
          this.setState({ orderNotesClassName: null });
        } else {
          this.GetNotes();
          this.setState({
            orderNotesClassName: null,
          });
        }
        break;
      }
      default:
        break;
    }
    this.setState({ tabIndex: newValue });
  }

  handleCloseOrderDetail = () => {
    this.props.handleCloseOrderDetail();
  };

  handleSingleDetailEdit = (currentOrder, object) => {
    this.setState({
      isLoading: true,
    });
    let apiResponseOriginal = Object.assign({}, this.state.currentOrder);

    object.CarrierReference = this.state.currentOrder.consignment_no;
    object.order_number = this.state.currentOrder.order_number;
    APIService.POST(
      AppSettings.SetSingleDetailUpdate + this.state.currentOrder.id,
      object
    )
      .then((response) => response.json())
      .then((response) => {
        if (response.success == true) {
          currentOrder = {
            ...apiResponseOriginal,
            ...currentOrder,
          };

          if (response.result) {
            currentOrder.latitude = response.result.latitude;
            currentOrder.longitude = response.result.longitude;
            currentOrder.what3words = response.result.what3words;
          }

          this.setState({
            currentOrder: currentOrder,
            apiResponseMessagePopup: response,
            isLoading: false,
          });
        } else {
          this.setState({
            apiResponseMessagePopup: response,
            isLoading: false,
          });
        }
      })
      .catch(() => {
        this.setState({
          apiResponseMessagePopup: {
            success: false,
            message: process.env.REACT_APP_DEFAULT_INTERNAL_SERVER_MESSAGE,
          },
        });
      });
  };

  AuditLog(is_install = false) {
    if (this.state.auditLogRows.length <= 0 || is_install) {
      this.setState({
        auditLogRows: [],
        auditLogLoading: true,
      });
      APIService.GET(AppSettings.GetOrderLog + this.state.currentOrder.id)
        .then((response) => response.json())
        .then((response) => {
          if (response.success == true) {
            this.setState({
              auditLogRows: response.result,
              auditLogLoading: false,
              refreshBTN: false,
              progressBarClassName: "d-none",
              auditLogClassName: null,
            });
          } else {
            this.setState({
              apiResponseMessagePopup: response,
              progressBarClassName: "d-none",
            });
          }
        })
        .catch(() => {
          this.setState({
            apiResponseMessagePopup: {
              success: false,
              message: process.env.REACT_APP_DEFAULT_INTERNAL_SERVER_MESSAGE,
            },
          });
        });
    } else {
      this.setState({
        progressBarClassName: "d-none",
        refreshBTN: false,
        auditLogClassName: null,
      });
    }
    // this.setState({ refreshBTN: false });
  }

  // Call when refresh the page
  componentDidMount() {
    let filtration = Object.assign({}, this.state.filtration);
    filtration.orderId = this.props.order.id;
    this.ApiRequest(filtration);
  }

  render() {
    const { classes } = this.props;

    return (
      <Dialog
        className="order-details-popup"
        open={this.state.isOpenOrderDetail}
        onClose={this.handleCloseOrderDetail}
        fullWidth
        maxWidth={"xl"}
        scroll="paper"
      >
        <Button className="btn-close" onClick={this.handleCloseOrderDetail}>
          <CloseIcon />
        </Button>
        {this.state.currentOrder && (
          <>
            <DialogTitle id="scroll-dialog-title">
              <div className="left-side">
                <OrderIcon />
                <span className="number">
                  {this.state.currentOrder.order_number}
                </span>
                <span className="order-status">
                  <OrderStatus code={this.state.currentOrder.status_id} />
                </span>
                <span className="updated">
                  <span className="text">Updated: </span>
                  <span className="date">
                    {DateConvertor(this.state.currentOrder.updated_at)}
                  </span>
                </span>
              </div>
              <div className="right-side">
                <div className="next-previous-order">
                  {this.state.previousOrder && (
                    <span
                      className="previous"
                      onClick={() => {
                        let filtration = Object.assign(
                          {},
                          this.state.filtration
                        );

                        filtration.orderId = this.state.previousOrder.id;
                        filtration.start--;
                        this.ApiRequest(filtration);
                      }}
                    >
                      <LeftArrowIcon />
                      <span>
                        {" "}
                        Order: {this.state.previousOrder.order_number}
                      </span>
                    </span>
                  )}
                  {this.state.previousOrder != null &&
                    this.state.nextOrder != null && (
                      <span className="center"></span>
                    )}
                  {this.state.nextOrder && (
                    <span
                      className="next"
                      onClick={() => {
                        let filtration = Object.assign(
                          {},
                          this.state.filtration
                        );

                        filtration.orderId = this.state.nextOrder.id;
                        filtration.start++;
                        this.ApiRequest(filtration);
                      }}
                    >
                      <span>Order: {this.state.nextOrder.order_number} </span>{" "}
                      <RightArrowIcon />
                    </span>
                  )}
                </div>
              </div>
            </DialogTitle>
            {!this.state.isLoading && (
              <DialogContent
                dividers={scroll === "paper"}
                className="popup-content"
              >
                <div className="Order-Popup">
                  <Paper square className="popup-tab">
                    <Tabs
                      indicatorColor="primary"
                      textColor="primary"
                      value={this.state.tabIndex}
                      onChange={this.tabChanger}
                      aria-label="disabled tabs example"
                    >
                      <Tab label="Overview" />
                      <Tab label="audit log" />
                      <Tab label="notes" />
                    </Tabs>
                    {this.state.tabIndex == 0 &&
                      this.state.isOrderDetailEditable && (
                        <div className="edit-information">
                          <span>Fields marked with</span>
                          <PencilEditIcon />
                          <span>click to edit.</span>
                        </div>
                      )}
                  </Paper>
                  <div className={classes.contenHeight + " orderPopup-height "}>
                    <Box
                      mt={2}
                      className={
                        this.state.progressBarClassName +
                        " Order-Popup progress-bar-class"
                      }
                    >
                      <ProgressBarHelper />
                    </Box>
                    <Box
                      mt={2}
                      className={
                        this.state.overviewClassName +
                        " Order-Popup " +
                        classes.fullWidth
                      }
                    >
                      {this.state.currentOrder != null && (
                        <Grid container spacing={3} className="overview">
                          <Grid item md={4}>
                            <Grid container spacing={3}>
                              <Grid
                                item
                                md={8}
                                style={{ paddingRight: "16px" }}
                              >
                                <EditableBox
                                  isEditable={this.state.isOrderDetailEditable}
                                  onSave={(value) => {
                                    var names = value.split(" ");
                                    var output = {};
                                    if (names.length > 2) {
                                      output = [
                                        {
                                          firstname: names[0],
                                          middlenames: names
                                            .slice(1, -1)
                                            .join(" "),
                                          lastname: names[names.length - 1],
                                        },
                                      ];
                                    } else if (names.length < 2) {
                                      output = [
                                        {
                                          firstname: names[0],
                                          middlenames: "",
                                          lastname: "",
                                        },
                                      ];
                                    } else {
                                      output = [
                                        {
                                          firstname: names[0],
                                          middlenames: "",
                                          lastname: names[names.length - 1],
                                        },
                                      ];
                                    }

                                    this.handleSingleDetailEdit(
                                      output[0],
                                      output[0]
                                    );
                                  }}
                                  singleLineForm
                                  value={`${
                                    this.state.currentOrder.first_name ?? ""
                                  } ${
                                    this.state.currentOrder.middle_name ?? ""
                                  } ${this.state.currentOrder.last_name ?? ""}`}
                                  title=""
                                  translate="translate(-00%, -60%)"
                                  width={"400px"}
                                  className="customer-name"
                                />
                                <EditableBox
                                  isEditable={this.state.isOrderDetailEditable}
                                  onSave={(value) => {
                                    this.handleSingleDetailEdit(value, {
                                      updateAddress: value,
                                    });
                                  }}
                                  singleLineForm
                                  value={{
                                    addressline1:
                                      this.state.currentOrder.addressline1,
                                    addressline2:
                                      this.state.currentOrder.addressline2,
                                    addressline3:
                                      this.state.currentOrder.addressline3,
                                    city: this.state.currentOrder.city,
                                    state: this.state.currentOrder.state,
                                    zipcode: this.state.currentOrder.zipcode,
                                    country: this.state.currentOrder.country,
                                  }}
                                  title=""
                                  translate="translate(-00%, -60%)"
                                  width={"400px"}
                                  className="address"
                                />
                                <EditableBox
                                  className="what3words"
                                  isEditable={this.state.isOrderDetailEditable}
                                  onSave={(value) => {
                                    this.handleSingleDetailEdit(
                                      { what3words: value },
                                      { what3words: value }
                                    );
                                  }}
                                  singleLineForm
                                  value={
                                    this.state.currentOrder.what3words ?? ""
                                  }
                                  title={
                                    ""
                                    // !this.state.currentOrder.what3words
                                    //   ? "What3Words: "
                                    //   : ""
                                  }
                                  translate="translate(-00%, -80%)"
                                  width={"400px"}
                                  link={
                                    "https://what3words.com/" +
                                      this.state.currentOrder.what3words ?? ""
                                  }
                                  emptyMessage=""
                                />
                              </Grid>
                              <Grid
                                item
                                md={4}
                                xs={12}
                                style={{ padding: "12px 16px" }}
                              >
                                <EditableBox
                                  isEditable={this.state.isOrderDetailEditable}
                                  onSave={(value) => {
                                    this.handleSingleDetailEdit(
                                      { phone: value },
                                      { ContactMobile: value }
                                    );
                                  }}
                                  value={this.state.currentOrder.phone ?? ""}
                                  title="Mobile"
                                  link={"tel:" + this.state.currentOrder.phone}
                                  emptyMessage="N/A"
                                />
                                <EditableBox
                                  isEditable={this.state.isOrderDetailEditable}
                                  onSave={(value) => {
                                    this.handleSingleDetailEdit(
                                      { home: value },
                                      { ContactHomeMobile: value }
                                    );
                                  }}
                                  value={this.state.currentOrder.home ?? ""}
                                  title="Home"
                                  link={"tel:" + this.state.currentOrder.home}
                                  emptyMessage="N/A"
                                />
                                <EditableBox
                                  isEditable={this.state.isOrderDetailEditable}
                                  onSave={(value) => {
                                    this.handleSingleDetailEdit(
                                      { work: value },
                                      { ContactWorkMobile: value }
                                    );
                                  }}
                                  value={this.state.currentOrder.work ?? ""}
                                  title="Work"
                                  link={"tel:" + this.state.currentOrder.work}
                                  emptyMessage="N/A"
                                />
                              </Grid>
                              <Grid
                                item
                                md={12}
                                xs={12}
                                style={{ paddingTop: 0 }}
                              >
                                <EditableBox
                                  isEditable={this.state.isOrderDetailEditable}
                                  onSave={(value) => {
                                    this.handleSingleDetailEdit(
                                      { email: value },
                                      { ContactEmail: value }
                                    );
                                  }}
                                  singleLineForm
                                  value={this.state.currentOrder.email}
                                  title="Email : "
                                  translate="translate(-00%, -80%)"
                                  width={"400px"}
                                  link={
                                    "mailto:" + this.state.currentOrder.email
                                  }
                                  emptyMessage="N/A"
                                />
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              md={12}
                              className={" MapBox-Class " + classes.mapBoxClass}
                              id={"mapBox-id"}
                            >
                              <MapBoxHelper detail={this.state.currentOrder} />
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            md={4}
                            xs={12}
                            style={{ padding: "12px 45px 12px 16px" }}
                            className="Dialog center-div"
                          >
                            <Paper className="paperDialog">
                              {this.state.currentOrder.consignment_no && (
                                <Typography
                                  variant="h6"
                                  display="block"
                                  gutterBottom
                                  className="consignment-no"
                                >
                                  <ConsignmentNoHelper
                                    no={this.state.currentOrder.consignment_no}
                                  >
                                    <img src={"/images/nextDayTruck.jpg"} />
                                    <span>
                                      {this.state.currentOrder.consignment_no}
                                    </span>
                                  </ConsignmentNoHelper>
                                </Typography>
                              )}

                              <EditableBox
                                isEditable={this.state.isOrderDetailEditable}
                                onSave={(value) => {
                                  this.handleSingleDetailEdit(
                                    { delivery_instructions: value },
                                    { delivery_instructions: value }
                                  );
                                }}
                                value={
                                  this.state.currentOrder
                                    .delivery_instructions ?? ""
                                }
                                title="Delivery Instructions"
                                translate="translate(-00%, -40% )"
                                width={"100%"}
                                className={"delivery-instructions"}
                                multiline
                                emptyMessage="There are no delivery instructions"
                              />

                              {this.state.currentOrder.consignment_no && (
                                <div className="services">
                                  <Typography
                                    variant="overline"
                                    display="block"
                                    gutterBottom
                                    className="labelHeading"
                                  >
                                    services
                                  </Typography>
                                  <table>
                                    <tr>
                                      <td>Service Type: </td>
                                      <td>
                                        {this.state.currentOrder.service_type}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Service Level: </td>
                                      <td>
                                        {this.state.currentOrder.service_level}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Delivery Point: </td>
                                      <td>
                                        {this.state.currentOrder.delivery_point}
                                      </td>
                                    </tr>
                                  </table>
                                  <table>
                                    <tr>
                                      <td>Items Unpacked: </td>
                                      <td>
                                        {this.getServiceValue(
                                          this.state.currentOrder.items_unpacked
                                        )}
                                      </td>
                                    </tr>

                                    <tr>
                                      <td>Packaging Removed: </td>
                                      <td>
                                        {this.getServiceValue(
                                          this.state.currentOrder
                                            .packaging_removed
                                        )}
                                      </td>
                                    </tr>

                                    <tr>
                                      <td>Assembly: </td>
                                      <td>
                                        {this.getServiceValue(
                                          this.state.currentOrder.assembly
                                        )}
                                      </td>
                                    </tr>

                                    <tr>
                                      <td>Disassembly: </td>
                                      <td>
                                        {this.getServiceValue(
                                          this.state.currentOrder.disassembly
                                        )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Collect Disposal: </td>
                                      <td>
                                        {this.getServiceValue(
                                          this.state.currentOrder
                                            .collect_disposal
                                        )}
                                      </td>
                                    </tr>
                                  </table>
                                </div>
                              )}
                            </Paper>
                          </Grid>
                          <Grid item md={4}>
                            <div className={"count-and-pagination"}>
                              <span className="product-count">
                                {this.state.currentOrder.products.length}
                                {this.state.currentOrder.products.length > 1
                                  ? " Products"
                                  : " Product"}
                              </span>
                              <p
                                className="product-pagination"
                                style={{
                                  display:
                                    this.state.currentOrder.products.length == 1
                                      ? "none"
                                      : "inline-flex",
                                }}
                              >
                                <span id="carousel-status"></span>
                                <button
                                  id="slider-custom-prev-button"
                                  onClick={() => {
                                    document
                                      .getElementsByClassName(
                                        "control-arrow control-prev"
                                      )[0]
                                      .click();
                                  }}
                                >
                                  <LeftArrow />
                                </button>
                                <button
                                  id="slider-custom-next-button"
                                  onClick={() => {
                                    document
                                      .getElementsByClassName(
                                        "control-arrow control-next"
                                      )[0]
                                      .click();
                                  }}
                                >
                                  <RightArrow />
                                </button>
                              </p>
                            </div>

                            <Paper className="paperDialog">
                              <Carousel
                                className={
                                  this.state.currentOrder.products.length > 1
                                    ? "MoreProducts"
                                    : "OneProduct"
                                }
                                // onChange={(a, b) => {
                                //   document.getElementById("carousel-status").innerHTML = document.getElementsByClassName("carousel-status")[0].innerHTML;

                                // }}
                                statusFormatter={(current) => {
                                  if (
                                    document.getElementById(
                                      "carousel-status"
                                    ) != null
                                  ) {
                                    document.getElementById(
                                      "carousel-status"
                                    ).innerHTML =
                                      current +
                                      "/" +
                                      this.state.currentOrder.products.length;
                                    if (
                                      document
                                        .getElementsByClassName(
                                          "control-arrow control-next"
                                        )[0]
                                        .classList.contains("control-disabled")
                                    ) {
                                      document
                                        .getElementById(
                                          "slider-custom-next-button"
                                        )
                                        .setAttribute("disabled", "true");
                                    } else {
                                      document
                                        .getElementById(
                                          "slider-custom-next-button"
                                        )
                                        .removeAttribute("disabled");
                                    }

                                    if (
                                      document
                                        .getElementsByClassName(
                                          "control-arrow control-prev"
                                        )[0]
                                        .classList.contains("control-disabled")
                                    ) {
                                      document
                                        .getElementById(
                                          "slider-custom-prev-button"
                                        )
                                        .setAttribute("disabled", "true");
                                    } else {
                                      document
                                        .getElementById(
                                          "slider-custom-prev-button"
                                        )
                                        .removeAttribute("disabled");
                                    }
                                  }
                                  return (
                                    current +
                                    "/" +
                                    this.state.currentOrder.products.length
                                  );
                                }}
                                // showArrows={"true"}
                              >
                                {this.state.currentOrder.products.map(
                                  (product) => (
                                    <div
                                      key={product.id}
                                      style={{ float: "left", width: "100%" }}
                                    >
                                      <img
                                        style={{
                                          minHeight: "270px",
                                        }}
                                        className="CarouselImage"
                                        src={product.img ?? "##"}
                                        onError={(e) => {
                                          e.target.onerror = null;
                                          e.target.src =
                                            "/images/placeholder.jpg";
                                        }}
                                      />
                                      {product.weight != "" &&
                                        product.weight != 0 && (
                                          <span
                                            className="Product-wight"
                                            // style={{ width: "100px" }}
                                          >
                                            {parseInt(product.weight)}
                                            <span> Kg </span>
                                          </span>
                                        )}
                                      <div style={{ padding: "0px 10px" }}>
                                        <p className="legend dialogText fw-500 product-id-and-barcode">
                                          <span className="Float-Left fw-500 product-title">
                                            <span
                                              className={
                                                "product-del-active " +
                                                (product.shopify_refunded_datetime !=
                                                  null ||
                                                this.state.currentOrder
                                                  .status_id ==
                                                  OrderStatusEnum.ORDER_CANCELLED()
                                                  ? "deleted"
                                                  : "active")
                                              }
                                            />
                                            {product.title}
                                          </span>
                                          <span className="Float-Right product-code">
                                            {product.product_code}
                                          </span>
                                        </p>
                                        <p className="product-description">
                                          {product.description}
                                        </p>
                                        {this.state.currentOrder
                                          .consignment_no && (
                                          <p className="delivery-and-barcode">
                                            <ConsignmentNoHelper
                                              no={
                                                this.state.currentOrder
                                                  .consignment_no
                                              }
                                            >
                                              <Button
                                                variant="contained"
                                                className=" delivery-button"
                                              >
                                                <DeliveryIcon className="mr-10" />
                                                Delivery
                                              </Button>
                                            </ConsignmentNoHelper>
                                            <span className="barcode">
                                              <BarcodeIcon />
                                              {product.bjs_barcode}
                                            </span>
                                          </p>
                                        )}
                                      </div>
                                    </div>
                                  )
                                )}
                              </Carousel>
                            </Paper>
                          </Grid>
                        </Grid>
                      )}
                      {!this.state.currentOrder && (
                        <Grid container spacing={3} className="overview">
                          <Grid item md={4}>
                            <Grid container spacing={3}>
                              <Grid
                                item
                                md={8}
                                style={{ paddingRight: "16px" }}
                              >
                                <Skeleton
                                  style={{ height: "18px", marginTop: "10px" }}
                                  animation="wave"
                                  variant="rectangular"
                                />
                                <Skeleton
                                  style={{ height: "14px", marginTop: "10px" }}
                                  animation="wave"
                                  variant="rectangular"
                                />
                                <Skeleton
                                  style={{ height: "14px", marginTop: "10px" }}
                                  animation="wave"
                                  variant="rectangular"
                                />
                                <Skeleton
                                  style={{ height: "14px", marginTop: "10px" }}
                                  animation="wave"
                                  variant="rectangular"
                                />
                                <Skeleton
                                  style={{ height: "14px", marginTop: "10px" }}
                                  animation="wave"
                                  variant="rectangular"
                                />
                                <Skeleton
                                  style={{ height: "14px", marginTop: "10px" }}
                                  animation="wave"
                                  variant="rectangular"
                                />
                              </Grid>
                              <Grid
                                item
                                md={4}
                                xs={12}
                                style={{ padding: "12px 16px" }}
                              >
                                <Skeleton
                                  style={{ height: "14px", marginTop: "10px" }}
                                  animation="wave"
                                  variant="rectangular"
                                />
                                <Skeleton
                                  style={{ height: "16px", marginTop: "10px" }}
                                  animation="wave"
                                  variant="rectangular"
                                />
                                <Skeleton
                                  style={{ height: "14px", marginTop: "20px" }}
                                  animation="wave"
                                  variant="rectangular"
                                />
                                <Skeleton
                                  style={{ height: "16px", marginTop: "10px" }}
                                  animation="wave"
                                  variant="rectangular"
                                />
                                <Skeleton
                                  style={{ height: "14px", marginTop: "20px" }}
                                  animation="wave"
                                  variant="rectangular"
                                />
                                <Skeleton
                                  style={{ height: "16px", marginTop: "10px" }}
                                  animation="wave"
                                  variant="rectangular"
                                />
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              md={12}
                              xs={12}
                              style={{ padding: "12px 16px" }}
                            >
                              <Skeleton
                                sx={{ height: 15 }}
                                style={{ marginTop: "10px" }}
                                animation="wave"
                                variant="rectangular"
                              />
                              <Skeleton
                                sx={{ height: 150 }}
                                style={{ marginTop: "20px" }}
                                animation="wave"
                                variant="rectangular"
                              />
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            md={4}
                            xs={12}
                            style={{ padding: "12px 16px" }}
                            className="Dialog"
                          >
                            <Paper className="paperDialog">
                              <Skeleton
                                style={{ height: "60px", marginTop: "10px" }}
                                animation="wave"
                                variant="rectangular"
                              />
                              <Skeleton
                                style={{ height: "14px", marginTop: "10px" }}
                                animation="wave"
                                variant="rectangular"
                              />
                              <Skeleton
                                style={{ height: "60px", marginTop: "10px" }}
                                animation="wave"
                                variant="rectangular"
                              />
                              <Skeleton
                                style={{ height: "24px", marginTop: "30px" }}
                                animation="wave"
                                variant="rectangular"
                              />
                              <Skeleton
                                style={{ height: "150px", marginTop: "10px" }}
                                animation="wave"
                                variant="rectangular"
                              />
                            </Paper>
                          </Grid>
                          <Grid item md={4}>
                            <Skeleton
                              style={{ height: "24px", marginTop: "10px" }}
                              animation="wave"
                              variant="rectangular"
                            />
                            <Skeleton
                              style={{ height: "340px", marginTop: "10px" }}
                              animation="wave"
                              variant="rectangular"
                            />
                          </Grid>
                        </Grid>
                      )}
                    </Box>
                    <Box
                      mt={2}
                      className={
                        this.state.auditLogClassName +
                        " Order-Popup Audit-log-grid " +
                        classes.fullWidth
                      }
                    >
                      <div>
                        <Grid container spacing={3}>
                          <Grid item md={12} style={{ overflow: "auto" }}>
                            <TableContainer
                              classes={{ root: classes.customTableContainer }}
                              className="audit-log-datagrid"
                            >
                              <Table stickyHeader>
                                <TableHead className="">
                                  <TableRow>
                                    <TableCell>
                                      <CheckBoxHelper
                                        onChange={(event, newVal) => {
                                          let SelectedOrders = [];
                                          if (newVal) {
                                            !this.state.auditLogLoading &&
                                              this.state.auditLogRows.length >
                                                0 &&
                                              this.state.auditLogRows.map(
                                                (row) => {
                                                  SelectedOrders.push(row.id);
                                                }
                                              );
                                          }
                                          this.setState({
                                            auditLogSelectedColumns:
                                              SelectedOrders,
                                          });
                                        }}
                                        checked={
                                          this.state.auditLogRows.length ==
                                          this.state.auditLogSelectedColumns
                                            .length
                                        }
                                      />
                                    </TableCell>
                                    <TableCell>DATE</TableCell>
                                    <TableCell>LOGGED BY</TableCell>
                                    <TableCell>EVENT</TableCell>
                                    <TableCell>
                                      <div className="exportCSV">
                                        <Button
                                          onClick={() => {
                                            this.setState({ refreshBTN: true });
                                            this.AuditLog(true);
                                          }}
                                        >
                                          <AutorenewIcon
                                            className={
                                              this.state.refreshBTN === true
                                                ? " refreshBTN "
                                                : "  "
                                            }
                                          />
                                        </Button>
                                        <Button
                                          className="border-radius-50 float-right btn-export"
                                          variant="contained"
                                          onClick={this.ExportLogCsv}
                                        >
                                          <ExportCsvIcon /> Export CSV
                                        </Button>
                                      </div>
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {!this.state.auditLogLoading &&
                                    this.state.auditLogRows.length > 0 &&
                                    this.state.auditLogRows.map(
                                      (row, index) => (
                                        <TableRow
                                          key={index}
                                          className={
                                            this.state.auditLogSelectedColumns.includes(
                                              row
                                            ) === true
                                              ? "active"
                                              : ""
                                          }
                                        >
                                          <TableCell className="check-box-td">
                                            <CheckBoxHelper
                                              onChange={(event, newVal) => {
                                                let auditLogSelectedColumns =
                                                  this.state
                                                    .auditLogSelectedColumns;

                                                if (newVal) {
                                                  auditLogSelectedColumns.push(
                                                    row.id
                                                  );
                                                } else {
                                                  const index =
                                                    auditLogSelectedColumns.indexOf(
                                                      row.id
                                                    );
                                                  if (index > -1) {
                                                    auditLogSelectedColumns.splice(
                                                      index,
                                                      1
                                                    ); // 2nd parameter means remove one item only
                                                  }
                                                }
                                                this.setState({
                                                  auditLogSelectedColumns:
                                                    auditLogSelectedColumns,
                                                });
                                              }}
                                              checked={
                                                this.state.auditLogSelectedColumns.includes(
                                                  row.id
                                                ) === true
                                                  ? true
                                                  : false
                                              }
                                            />
                                          </TableCell>
                                          <TableCell>
                                            {DateConvertor(row.event_datetime)}
                                          </TableCell>
                                          <TableCell>
                                            {" "}
                                            <OrderEventLoggedBy
                                              code={row.logged_by}
                                            />
                                          </TableCell>
                                          <TableCell>
                                            <div
                                              className="line-height-12"
                                              dangerouslySetInnerHTML={{
                                                __html: row.event,
                                              }}
                                            />
                                          </TableCell>
                                          <TableCell className="">
                                            <div className="copy-cell">
                                              <CopyToClipboard
                                                text={`Date : ${row.event_datetime}\nEvent Code : ${row.EventCode}\nEvent : ${row.event}\nEvent Note : ${row.EventNotes}`}
                                                onCopy={() => {
                                                  this.setState({
                                                    CopyContent: true,
                                                  });
                                                }}
                                              >
                                                <span className="copyContent">
                                                  <FileCopyIcon className="curser-pointer" />
                                                </span>
                                              </CopyToClipboard>
                                            </div>
                                          </TableCell>
                                        </TableRow>
                                      )
                                    )}
                                  {this.state.auditLogLoading && (
                                    <>
                                      {Array.from(new Array(10)).map(
                                        (val, index) => (
                                          <TableRow key={index}>
                                            <TableCell colSpan={12}>
                                              <Skeleton
                                                animation="wave"
                                                variant="rectangular"
                                                height={40}
                                                width={"100%"}
                                              />
                                            </TableCell>
                                          </TableRow>
                                        )
                                      )}
                                    </>
                                  )}
                                  {!this.state.auditLogLoading &&
                                    this.state.auditLogRows.length == 0 && (
                                      <TableRow>
                                        <TableCell colSpan={12}>
                                          <NoResultHelper />
                                        </TableCell>
                                      </TableRow>
                                    )}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Grid>
                        </Grid>
                      </div>
                    </Box>

                    <Box
                      mt={2}
                      className={
                        this.state.orderNotesClassName +
                        " Order-Popup " +
                        classes.fullWidth
                      }
                    >
                      <Grid container spacing={0} className="order-note">
                        <Grid md={12} item>
                          <Box
                            display={"flex"}
                            ml={2}
                            mr={2}
                            mb={2}
                            p={0}
                            className="order-note-heading"
                          >
                            <Typography variant="h6" className="notes">
                              <NotesIcon /> Notes
                            </Typography>
                          </Box>
                          <Box ml={2} mr={2} p={0}>
                            {this.state.orderNotesData != null &&
                              this.state.orderNotesData.length > 0 &&
                              this.state.orderNotesData.map((row, index) => (
                                <Box key={index} className="list-note">
                                  <div>
                                    <span className="note-sender-image">
                                      <AccountCircleIcon />
                                    </span>
                                    <span className="note-sender">
                                      {APIService.GetSelectedStoreName()}
                                    </span>
                                    <span className="note-created-at">
                                      {DateConvertor(row.created_at)}
                                    </span>
                                  </div>
                                  <Typography gutterBottom className="note">
                                    {row.notes}
                                  </Typography>
                                </Box>
                              ))}
                            {this.state.orderNotesData !== null &&
                              this.state.orderNotesData.length == 0 &&
                              this.state.editNote.isEditable !== true && (
                                <Box className="no-note">
                                  <Typography gutterBottom className="text">
                                    There are currently no notes within this
                                    order.
                                  </Typography>
                                </Box>
                              )}
                            {this.state.orderNotesData === null &&
                              this.state.editNote.isEditable !== true &&
                              Array.from(new Array(2)).map((item, index) => (
                                <Box key={index} className="list-note">
                                  <div>
                                    <span className="note-sender-image">
                                      <Skeleton
                                        animation="wave"
                                        variant="circular"
                                        width={40}
                                        height={40}
                                      />
                                    </span>
                                    <span className="note-sender">
                                      <Skeleton
                                        animation="wave"
                                        width="250px"
                                        height="40px"
                                      />
                                    </span>
                                    <span className="note-created-at">
                                      <Skeleton
                                        animation="wave"
                                        width="150px"
                                        height="40px"
                                      />
                                    </span>
                                  </div>
                                  <Skeleton
                                    className="note"
                                    animation="wave"
                                    style={{
                                      borderRadius: "6px",
                                      height: "100px",
                                    }}
                                    width={"100%"}
                                  />
                                </Box>
                              ))}
                            {(this.state.editNote.isEditable === true ||
                              this.state.editNote.isEditable === null) && (
                              <Box className="list-note">
                                <div>
                                  <span className="note-sender-image">
                                    <AccountCircleIcon />
                                  </span>
                                  <span className="note-sender">
                                    {APIService.GetSelectedStoreName()}
                                  </span>
                                </div>
                                <TextField
                                  size="small"
                                  multiline
                                  fullWidth
                                  disabled={!this.state.editNote.isEditable}
                                  variant="outlined"
                                  borderRadius={16}
                                  className="textarea-note  list-note note"
                                  value={this.state.editNote.value}
                                  onChange={(event) => {
                                    let editNote = this.state.editNote;
                                    editNote.value = event.target.value;
                                    this.setState({
                                      editNote: editNote,
                                    });
                                  }}
                                  placeholder="Add Note"
                                  rows={4}
                                />
                              </Box>
                            )}
                            <div className="add-cancel-note">
                              {this.state.currentOrder && (
                                <Button
                                  variant="text"
                                  disabled={
                                    !this.state.currentOrder ||
                                    !this.state.currentOrder.consignment_no ||
                                    this.state.currentOrder.consignment_no == ""
                                      ? true
                                      : false
                                  }
                                  startIcon={
                                    this.state.editNote.isEditable === false ? (
                                      <NoteAddIcon />
                                    ) : (
                                      ""
                                    )
                                  }
                                  className={
                                    this.state.editNote.isEditable === false
                                      ? "add-note"
                                      : "cancel"
                                  }
                                  onClick={() => {
                                    let editNote = this.state.editNote;
                                    editNote.isEditable =
                                      !this.state.editNote.isEditable;
                                    this.setState({ editNote: editNote });
                                  }}
                                >
                                  {this.state.editNote.isEditable === false
                                    ? "Add Note"
                                    : "Cancel"}
                                </Button>
                              )}
                              {(this.state.editNote.isEditable === true ||
                                this.state.editNote.isEditable === null) && (
                                <Button
                                  endIcon={
                                    this.state.editNote.isEditable === true ? (
                                      <NoteAddIcon className="editIcon" />
                                    ) : (
                                      <CircularProgress className="progressBar" />
                                    )
                                  }
                                  variant="contained"
                                  className="add-note "
                                  onClick={() => {
                                    if (
                                      this.state.editNote.isEditable !== null
                                    ) {
                                      let editNote = this.state.editNote;
                                      editNote.isEditable = null;
                                      this.setState({
                                        editNote: editNote,
                                      });
                                      this.AddNotes();
                                    }
                                  }}
                                >
                                  Add Note
                                </Button>
                              )}
                            </div>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                    <Snackbar
                      autoHideDuration={3000}
                      open={this.state.CopyContent}
                      onClose={() => this.setState({ CopyContent: false })}
                      className={"CopyContentAlert"}
                    >
                      <Alert severity={"success"}> Copied Content </Alert>
                    </Snackbar>
                    <Snackbar
                      autoHideDuration={3000}
                      open={this.state.refreshBTN}
                      onClose={() => this.setState({ refreshBTN: false })}
                      className={"CopyContentAlert"}
                    >
                      <Alert severity={"success"}> Refreshed </Alert>
                    </Snackbar>
                    {this.state.apiResponseMessagePopup && (
                      <APIResponsePopupHelper
                        result={this.state.apiResponseMessagePopup}
                        successClose={() => {
                          this.setState({ apiResponseMessagePopup: false });
                        }}
                        failureClose={() => {
                          this.setState({ apiResponseMessagePopup: false });
                        }}
                      />
                    )}
                  </div>
                </div>
              </DialogContent>
            )}
          </>
        )}
        {(!this.state.currentOrder || this.state.isLoading) && (
          <>
            <LoadingOnPopupHelper className={classes.loaderHeight} />
          </>
        )}
        {this.state.apiResponseMessagePopup && (
          <APIResponsePopupHelper
            result={this.state.apiResponseMessagePopup}
            successClose={() => {
              this.setState({ apiResponseMessagePopup: false });
            }}
            failureClose={() => {
              this.setState({ apiResponseMessagePopup: false });
            }}
          />
        )}
      </Dialog>
    );
  }
}

// export default ;
export default withStyles(styles, { withTheme: true })(OrderDialogBox);

class EditableBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditable: false,
      value: this.props.value ?? "",
    };
  }

  // On Page changeDownloadCSV

  render() {
    return (
      <div className={"editable-text-box " + this.props.className}>
        <>
          {!this.props.singleLineForm && (
            <div className="text-box">
              <span className="text">{this.props.title}</span>
              {this.props.isEditable && (
                <span
                  className="edit"
                  onClick={() => {
                    this.setState({
                      isEditable: true,
                      value: this.props.value,
                    });
                  }}
                >
                  <PencilEditIcon />
                </span>
              )}
            </div>
          )}
          <AnimateSharedLayout type="crossfade">
            <motion.div layoutId="title-container">
              <div className="editable-box">
                {this.props.singleLineForm && (
                  <span className="text">{this.props.title}</span>
                )}
                <span
                  className={
                    "editable " +
                    (this.props.link && this.props.value == "" ? "empty" : "")
                  }
                >
                  {this.props.link &&
                    this.props.value == "" &&
                    this.props.emptyMessage}
                  {this.props.link && this.props.value != "" && (
                    <a target="_blank" href={this.props.link} rel="noreferrer">
                      {this.props.value}
                    </a>
                  )}
                  {!this.props.link && typeof this.props.value != "object" && (
                    <>
                      {this.props.value == ""
                        ? this.props.emptyMessage
                        : this.props.value}
                    </>
                  )}

                  {!this.props.link &&
                    typeof this.props.value == "object" &&
                    Object.keys(this.props.value).map((key) => (
                      <>
                        {this.props.value[key] && (
                          <>
                            {this.props.value[key]}
                            <br />
                          </>
                        )}
                      </>
                    ))}
                </span>
                {this.props.singleLineForm && this.props.isEditable && (
                  <span
                    className="edit"
                    onClick={() => {
                      this.setState({
                        isEditable: true,
                      });
                    }}
                  >
                    <PencilEditIcon />
                  </span>
                )}
              </div>
            </motion.div>
            <AnimatePresence>
              {this.state.isEditable && (
                <div
                  className={"editable-popup"}
                  style={{
                    pointerEvents: "auto",
                    transform: this.props.translate ?? "translate(-50%, -80%)",
                    width: this.props.width ?? "250px",
                  }}
                >
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0, transition: { duration: 0.15 } }}
                    transition={{ duration: 0.2, delay: 0.15 }}
                    style={{
                      pointerEvents: "auto",
                      // transform: 'translate(-50%, -80%)'
                    }}
                    className="body"
                  >
                    <motion.div
                      layoutId="title-container"
                      className="text-fields-and-buttons"
                    >
                      {typeof this.state.value != "object" && (
                        <OutlinedInput
                          fullWidth
                          autoFocus="true"
                          value={this.state.value}
                          onChange={(event) => {
                            this.setState({
                              value: event.target.value,
                            });
                          }}
                          multiline={this.props.multiline ?? false}
                          endAdornment={
                            <div className={"icons"}>
                              <span
                                className="save"
                                onClick={() => {
                                  this.props.onSave(this.state.value);
                                  this.setState({
                                    isEditable: false,
                                  });
                                }}
                              >
                                <GeneralCheckIcon />
                              </span>
                              <span
                                className="close"
                                onClick={() => {
                                  this.setState({
                                    isEditable: false,
                                    value: this.props.value,
                                  });
                                }}
                              >
                                <GeneralCloseIcon />
                              </span>
                            </div>
                          }
                        />
                      )}
                      {typeof this.state.value == "object" && (
                        <>
                          <div className="text-field">
                            {Object.keys(this.state.value).map((key, index) => (
                              <input
                                key={index}
                                type="text"
                                onChange={(event) => {
                                  let value = this.state.value;
                                  value[key] = event.target.value;
                                  this.setState({
                                    value: value,
                                  });
                                }}
                                placeholder={key}
                                value={this.state.value[key]}
                              />
                            ))}
                          </div>

                          <div className={"icons"}>
                            <span
                              className="save"
                              onClick={() => {
                                this.props.onSave(this.state.value);
                                this.setState({
                                  isEditable: false,
                                });
                              }}
                            >
                              <GeneralCheckIcon />
                            </span>
                            <span
                              className="close"
                              onClick={() => {
                                this.setState({
                                  isEditable: false,
                                  value: this.props.value,
                                });
                              }}
                            >
                              <GeneralCloseIcon />
                            </span>
                          </div>
                        </>
                      )}
                    </motion.div>
                  </motion.div>
                </div>
              )}
            </AnimatePresence>
          </AnimateSharedLayout>
        </>
      </div>
    );
  }
}

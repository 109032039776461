import React from "react";
import { createBrowserHistory } from "history";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

//pages for this product
import layout1 from "views/layouts/layout1";
import layout2 from "views/layouts/layout2";
import layout3 from "views/layouts/layout3";
import layout4 from "views/layouts/layout4";

import SettingsPage from "views/SettingsPage/SettingsPage.jsx";
import OrdersPage from "views/OrdersPage/OrdersPage.jsx";
import DeliverySchedulePage from "views/DeliverySchedulePage/DeliverySchedulePage.jsx";
import StoreValidationPage from "views/StoreValidationPage/StoreValidationPage";
import ProductsPage from "views/ProductsPage/ProductsPage";
import NotificationsPage from "views/NotificationsPage/NotificationsPage";
import DispatchesPage from "views/DispatchesPage/DispatchesPage";
import ErrorPage from "views/ErrorPage/ErrorPage.jsx";
import HelpPage from "views/HelpPage/IndexPage.jsx";
import StoreLandingPage from "views/StoreLandingPage/IndexPage.jsx";

export default function Routers() {
  const hist = createBrowserHistory();
  const routes = [
    {
      path: "/refine/store",
      component: layout4,
      routes: [
        {
          path: "/",
          component: StoreLandingPage,
        },
      ],
    },
    {
      path: "/help",
      component: layout3,
      routes: [
        {
          path: "/",
          component: HelpPage,
        },
      ],
    },
    {
      path: "/error",
      component: layout2,
      routes: [
        {
          path: "/",
          component: ErrorPage,
        },
      ],
    },
    {
      path: "/store/validation",
      component: layout2,
      routes: [
        {
          path: "/",
          component: StoreValidationPage,
        },
      ],
    },
    {
      path: "/",
      component: layout1,
      routes: [
        {
          path: "/app-settings",
          component: SettingsPage,
        },
        {
          path: "/orders",
          component: OrdersPage,
        },
        {
          path: "/delivery-schedule",
          component: DeliverySchedulePage,
        },

        {
          path: "/products",
          component: ProductsPage,
        },
        {
          path: "/dispatches",
          component: DispatchesPage,
        },
        {
          path: "/notifications",
          component: NotificationsPage,
        },
        {
          path: "/",
          component: OrdersPage,
        },
      ],
    },
  ];
  return (
    <Router history={hist}>
      <Switch>
        {" "}
        {routes.map((route, i) => (
          <RouteWithSubRoutes key={i} {...route} />
        ))}{" "}
      </Switch>{" "}
    </Router>
  );
}

function RouteWithSubRoutes(route) {
  return (
    <Route
      path={route.path}
      render={(props) => (
        // pass the sub-routes down to keep nesting
        <route.component {...props} routes={route.routes} />
      )}
    />
  );
}

// {
//   path: "/help",
//   component: layout3,
//   routes: [

//     {
//       path: "/",
//       component: layout4,
//       route: [
//         {
//           path: "/cat",
//           component: HelpCategoryPage,
//         },
//         {
//           path: "/help",
//           component: HelpPage,
//         },

//       ]
//     },

//   ],
// }

import React, { Component } from "react";
import { Link } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import { ReactComponent as BackIcon } from "../../assets/img/navigationIcons_LeftArrow.svg";
import Swal from "sweetalert2";
import AppSettings from "app-settings";
import APIService from "../../services/APIService.js";
import "./StoreLandingPage.scss";
import { LoadingOnPopupHelper } from "../helper/LoadingHelper";
const urlParams = new URLSearchParams(window.location.search);

export default class IndexPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      store: "",
    };
  }
  storeChange = (e) => {
    this.setState({ store: e.target.value });
  };
  submitStore = (e) => {
    if (e.key === "Enter") {
      this.handleStore();
    }
  };
  getSelectedStore = () => {
    let active_store = localStorage.getItem("stores");
    let active_st = null;
    if (active_store) {
      let store = JSON.parse(active_store);
      for (let st in store) {
        if (store[st].is_selected) {
          active_st = store[st].store_url;
        }
      }
    }
    return active_st;
  };
  handleStore = () => {
    const storeName =
      urlParams.get("shop") || this.state.store || this.getSelectedStore();
    if (/^[a-zA-Z0-9-_]+\.myshopify\.com$/g.test(storeName)) {
      const url = `?shop_domain=${storeName}`;
      APIService.GET(AppSettings.GetStoresByNameAPI + url)
        .then((response) => response.json())
        .then((response) => {
          if (response.success) {
            let store = response.result.stores;
            if (store.length === 0) {
              store = response.result.stores;
            }
            localStorage.setItem(
              "stores",
              JSON.stringify(response.result.stores)
            );
            this.props.history.push("/app-settings");
          }
        })
        .catch((err) => {
          Swal.fire({
            title: "Oops...",
            text: err,
            position: "top-center",
            icon: "error",
            confirmButtonText: "Close",
          });
        });
    } else {
      Swal.fire({
        title: "Oops...",
        html:
          "<body1>Unable to connect to your store at the moment. <br>" +
          "Go to your Linnworks <a href='https://www.linnworks.net/#/app/AppSuperStore' target='_blank'> apps dashboard</a> " +
          " then my applications to open <br><strong>Bjs Home Delivery</strong> app." +
          "<br><br>" +
          "<small>OR</small>" +
          "<br><br>" +
          "Follow <a href='/help'>documentation</a> to get onboard." +
          "</body1>",
        position: "top-center",
        icon: "info",
        confirmButtonText: "Close",
      }).then(function () {
        window.location.reload();
      });
    }
  };
  componentDidMount() {
    this.handleStore();
  }

  componentWillUnmount() {}

  render() {
    return (
      <div id="store-page" className="store">
        <div className="store-center">
          <div className="body">
            <Link to="/">
              <IconButton className="back-button">
                <BackIcon />
              </IconButton>
            </Link>
            <h1 className="pageTitle">Connecting to Your store</h1>
            <LoadingOnPopupHelper />
          </div>
        </div>
      </div>
    );
  }
}

import React, { Component } from "react";
import APIService from "../../services/APIService.js";
import ProgressBarHelper from "./../helper/ProgressBarHelper.jsx";
import AppSettings from "app-settings";
import ApiResponseError from "../helper/ApiResponseError.jsx";

class StoreValidationPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorAlert: (
        <div style={{ minHeight: "300px", display: "flex" }}>
          <ProgressBarHelper />
        </div>
      ),
    };
  }

  componentDidMount() {
    this.StartLoader();
    //alert("Hello");return false;
    this.ValidateAPIRequest();
  }
  // Start Loader And Empty Table
  StartLoader = () => {
    this.setState({ loading: true });
  };

  ValidateAPIRequest = () => {
    (async () => {
      if (
        this.props.location.search.length &&
        this.props.location.search.length > 0
      ) {
        APIService.GET(
          AppSettings.ValidateStoreAPI + this.props.location.search
        )
          .then((response) => response.json())
          .then((response) => {
            let store = [];
            if (response.success == true) {
              // var store = response.data.stores.filter(
              //   (x) => x.is_selected == true
              // );
              if (store.length == 0) {
                store = response.data.stores[0];
              }

              localStorage.setItem(
                "stores",
                JSON.stringify(response.data.stores)
              );
              localStorage.setItem(
                "store_config",
                JSON.stringify(response.data.store_config)
              );
              if (!store.is_configured) {
                this.props.history.push("/app-settings");
              } else {
                this.props.history.push("/orders");
              }

              // APIService.GET(AppSettings.GetSettingsAPI)
              //   .then((response) => response.json())
              //   .then((response) => {
              //     if (response && response.success) {
              //       if (response.result != null) {
              //         // this.setState({ isExist: true });
              //         localStorage.setItem(
              //           "store_config",
              //           JSON.stringify(response.result)
              //         );
              //         if (!store.is_configured) {
              //           this.props.history.push("/app-settings");
              //         } else {
              //           this.props.history.push("/orders");
              //         }
              //       } else {
              //         this.props.history.push("/app-settings");
              //       }
              //     } else {
              //       this.props.history.push("/app-settings");
              //     }
              //   })
              //   .catch(() => {
              //     this.setState({
              //       errorAlert: (
              //         <ApiResponseError
              //           response={response}
              //           onClick={() => {
              //             // this.props.history.push("/error/401");
              //             this.props.history.push("/help");
              //           }}
              //         />
              //       ),
              //     });
              //   });

              //if store is not configured then send user to settings page else to order page
            } else {
              this.setState({
                errorAlert: (
                  <ApiResponseError
                    response={response}
                    onClick={() => {
                      // this.props.history.push("/error/401");
                      this.props.history.push("/help");
                    }}
                  />
                ),
              });
            }
          })
          .catch(() => {
            this.setState({
              errorAlert: (
                <ApiResponseError
                  response={{
                    message:
                      process.env.REACT_APP_DEFAULT_INTERNAL_SERVER_MESSAGE,
                  }}
                  onClick={() => {
                    // this.props.history.push("/error/401");
                    this.props.history.push("/help");
                  }}
                />
              ),
            });
          });
      } else {
        this.props.history.push("/error/400");
      }
    })();
  };
  render() {
    return <div>{this.state.errorAlert}</div>;
  }
}
export default StoreValidationPage;

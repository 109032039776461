import React from "react";
import {
  AppBar,
  IconButton,
  Button,
  Box,
  makeStyles,
  Toolbar,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
// import React, { useLayoutEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as BJSTwoManLogo } from "../../assets/img/BJSTwoMan_Logo.svg";
import { ReactComponent as EnvironmentSwitchIcon } from "../../assets/img/icons_EnviromentSwitch.svg";
import { ReactComponent as SignOutArrowIcon } from "../../assets/img/icons_SignOutArrow.svg";
import { ReactComponent as SignOutNoArrowIcon } from "../../assets/img/icons_SignOutNoArrow.svg";
import APIService from "../../services/APIService.js";

import "./layout1.scss";
import ChangeStorePopup from "./Layout1Components/ChangeStorePopup.jsx";
import StoresListDropdown from "./Layout1Components/StoresList.jsx";

// window.addEventListener("focus", function () {
//   let selectedStoreId = document.getElementById("selected-store");
//   if (selectedStoreId) {
//     selectedStoreId = selectedStoreId.getAttribute("data-selected-store-id");
//     var stores = localStorage.getItem("stores");
//     if (stores && stores.length > 0) {
//       stores = JSON.parse(stores);
//       for (let i = 0; i < stores.length; i++) {
//         if (stores[i].id === selectedStoreId) {
//           stores[i].is_selected = true;
//         } else {
//           stores[i].is_selected = false;
//         }
//       }
//     }

//     if (!stores) {
//       // document.location = "/error/401";
//       document.location = "/help";
//     } else {
//       localStorage.setItem("stores", JSON.stringify(stores));
//     }
//   }
// });

const height = window.innerHeight - 74;
const drawerWidth = 180;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  main: {
    background: "white",
    marginTop: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: `${drawerWidth}px`,
    },
    overflow: "hidden",
    height: `${height}px`,
  },
  mainPage: {
    float: "left",
    width: "100%",
    minHeight: `${height - 100}px`,
  },
  horizontalLine: {
    paddingBottom: "3px!important",
    background: "#3A3630 !important",
  },
  main2: {
    height: `${height}px`,
    overflowY: "scroll",
    scrollBehavior: "smooth",
  },
  logo: {
    display: "block",
    width: "auto",
    margin: "auto",
    padding: "6px 0px",
  },
  menuButton: {
    marginRight: 0,
    borderRight: "1px solid #dbd4c488 !important",
    borderRadius: "0!important",

    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    top: "67px",
    width: drawerWidth,
    background: "#F0F0F0",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  grow: {
    flexGrow: 1,
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

export default function PrimarySearchAppBar(data) {
  let store = localStorage.getItem("stores");

  if (store == null) {
    // data.history.push("/error/401");
    data.history.push("/help");
    return <div></div>;
  }

  const [linkDisabled, setlinkDisabled] = React.useState(false);
  const [isLiveOrStaging, setIsLiveOrStaging] = React.useState(
    APIService.GetStoreConfig("is_live_api")
  );

  if (!APIService.CheckStoreApiExist()) {
    if (
      data &&
      "history" in data &&
      "location" in data.history &&
      "/app-settings" !== data.history.location.pathname
    )
      data.history.push("/app-settings");
    if (linkDisabled === false) {
      setlinkDisabled(true);
    }
  }

  const openIsStagingOrLiveFunction = (isPopup) => {
    setOpenIsStagingOrLive(isPopup);
  };

  const classes = useStyles();

  const menuId = "primary-search-account-menu";

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const [openIsStagingOrLive, setOpenIsStagingOrLive] = React.useState(0);

  return (
    <div className={classes.grow}>
      <AppBar position="static" className="menu">
        <Toolbar>
          <a
            rel="noreferrer"
            target="_blank"
            href="https://bjshomedelivery.com/"
            className="contact-support"
          >
            <BJSTwoManLogo className={classes.logo} />
          </a>
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <StoresListDropdown />
            <Link to="/" style={{ display: "flex" }}>
              <IconButton
                className="help menu-link exit"
                style={{ background: "black!important" }}
              >
                <img src="/images/icons_Help.svg" />
                <span className="text-line">
                  <span className="text">Exit</span>
                  <span className="line"></span>
                </span>
              </IconButton>
            </Link>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              edge="start"
              className={classes.menuButton}
              aria-label="open drawer"
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
            <StoresListDropdown />
            <div className="live-staging menu-link mobile">
              <Button
                onClick={() => {
                  setOpenIsStagingOrLive(true);
                }}
                variant="contained"
                className={
                  " button " + (isLiveOrStaging ? "production" : "staging")
                }
              >
                <EnvironmentSwitchIcon />
              </Button>
            </div>
            <IconButton
              aria-controls={menuId}
              aria-haspopup="true"
              className="sign-out menu-link mobile"
              onClick={() => {
                localStorage.clear();
                document.location = "https://www.bjshomedelivery.com/";
              }}
            >
              <div className="box-arrow">
                <SignOutArrowIcon className="arrow" />
                <SignOutNoArrowIcon />
              </div>{" "}
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      <Box className={classes.horizontalLine}></Box>
      {openIsStagingOrLive === true && (
        <ChangeStorePopup
          openIsStagingOrLiveFunction={openIsStagingOrLiveFunction}
          isLiveOrStaging={(res) => {
            setIsLiveOrStaging(res);
          }}
        />
      )}
    </div>
  );
}
